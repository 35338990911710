
const GraphResizeService = {
  checkResizeWidth: function(
    ref: React.MutableRefObject<HTMLDivElement|null>,
    width: number,
    setWidth: React.Dispatch<React.SetStateAction<number>>): void | (() => void)
  {
    if (!ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver((): void => {
      if (!ref.current) {
        return;
      }
      if(ref.current.offsetWidth !== width) {
        setWidth(ref.current.offsetWidth - 30);
      }
    });

    resizeObserver.observe(ref.current);

    return function cleanup() {
      resizeObserver.disconnect();
    }
  },
}

export default GraphResizeService;
