import { Divider } from "@mui/material";
import { TrackerAction } from "./TrackerAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
interface propsInterface {
    index: number;
}

export function TrackerActions(props: propsInterface) {
    const trackers = useSelector((state: RootState) => state.trackers);

    return (
        <div>
            <h4>Tracker</h4>

            <TrackerAction label="Redémarrer le tracking" value={"restart_tracking"} trackerId={trackers.data[props.index].id} ></TrackerAction>
            <TrackerAction label="Mettre à plat" value={"start_flat"} trackerId={trackers.data[props.index].id} ></TrackerAction>
            <TrackerAction label="Arrêter la mise à plat" value={"stop_flat"} trackerId={trackers.data[props.index].id} ></TrackerAction>
            <TrackerAction label="Ouvrir le reverse SSH" value={"start_ssh"} trackerId={trackers.data[props.index].id} ></TrackerAction>
            <TrackerAction label="Fermer le reverse SSH" value={"stop_ssh"} trackerId={trackers.data[props.index].id} ></TrackerAction>

            <div style={{marginTop: '20px'}}>
                <Divider></Divider>
            </div>

            <h4>Plateforme</h4>

            <TrackerAction label="Purger les alarmes courantes" value={"tracker_alarm_end_collection"} trackerId={trackers.data[props.index].id} ></TrackerAction>
        </div>
    )
}

