import TemporarilyUnavailableCard from '../TemporarilyUnavailableCard'

import { ImmersionHeater } from '../../../../interfaces/ImmersionHeater'

interface propsInterface {
    immersionHeater: ImmersionHeater;
}

export default function ImmersionHeaterCard(props: propsInterface) {
    return (
        <TemporarilyUnavailableCard title={'Accumulateur thermique - ' + props.immersionHeater.alias}></TemporarilyUnavailableCard>
    );
}