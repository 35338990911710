import { Subject } from 'rxjs';

const subject = new Subject<boolean>();

const LoadService = {
    open: function(): void {
      subject.next(true);
    },
    close: function(): void {
      subject.next(false);
    },
    subject: function(): Subject<boolean> {
      return subject;
    },
}

export default LoadService;
