import Box from '@mui/material/Box';
import PrivateRoutes from '../../PrivateRoutes';

function Resource() {
  return (
    <Box sx={{ display: 'flex' }}>
      <PrivateRoutes authField='authAppSoftware'/>
    </Box>
  );
}

export default Resource;
