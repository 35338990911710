export enum forceUnitType {
    kwh = 'kwh'
}

export function forceUnit(value: number, type: string) {
    if (type === forceUnitType.kwh) {
        return (value / 1000).toFixed(1);
    }

    return value;
}