import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiV2 from '../../../../service/api/ApiV2';
import { Dayjs } from 'dayjs';
import { Plant } from '../../../../interfaces/Plant';
import { REACT_APP_API_V2_URL } from '../../../../config';

type MigrationCardProps = {
    plant: Plant;
    startDate: Dayjs;
    endDate: Dayjs;
}

const apiV2Url = REACT_APP_API_V2_URL;
const MIGRATION_MODE = 3

export default function MigrationCard(props: MigrationCardProps) {

    function isGraphMigrationDisabled(): boolean {
        return props.endDate.format('YYYY-MM-DD') !== props.startDate.format('YYYY-MM-DD')
    }

    function handleGraphMigration(): void {
        apiV2.graphMigration(props.plant.id, props.startDate.format('YYYY-MM-DD'), MIGRATION_MODE);
    }

    function handlePurgeCache(): void {
        apiV2.purgeStatus(props.plant.id);
    }

    function handleNominalPowerCompute(): void {
        apiV2.nominalPowerCompute(props.plant.id);
    }

    function handleRawConsumptionData(): void {
        window.open(`${apiV2Url}/v2/admin/display/consumption/${props.plant.id}/${props.startDate.format('YYYY-MM-DD')}`, '_blank');
    }

    return (
        <Card className='scada-card' style={{ paddingRight: '15px', paddingLeft: '15px' }}>
            <Accordion  >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className='scada-card-title' >Actions de migration du site</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack sx={{mb: "10px"}}>
                        <Button variant="outlined" color="secondary" disabled={isGraphMigrationDisabled()} onClick={handleGraphMigration}>
                            Recalcul du jour
                        </Button>
                        <Button variant="outlined" color="secondary" sx={{ mt: "10px" }} onClick={handlePurgeCache}>
                            Purge cache
                        </Button>
                        <Button variant="outlined" color="secondary" sx={{ mt: "10px" }} onClick={handleNominalPowerCompute}>
                            Recalcul puissance nominale
                        </Button>
                        <Button variant="outlined" color="secondary" sx={{ mt: "10px" }} onClick={handleRawConsumptionData}>
                            Données conso brutes
                        </Button>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Card>
    );
}