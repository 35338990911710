import React, { RefObject, ForwardRefRenderFunction, useImperativeHandle, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { SubmitHandler, Controller, useForm} from 'react-hook-form';
import { SerialNumber } from '../../../../../../interfaces/tracker/SerialNumber';

import { AppDispatch, RootState } from "../../../../../../store/store";
import { updateTrackerSerialNumber } from '../../../../../../store/scada/tracker/trackers.store';

type SerialNumberFormHandle = {
  submitForm: () => void;
};

type SerialNumberProps = {
  formRef: RefObject<SerialNumberFormHandle>;
  index: number;
  onSuccess: () => void;
}

const SerialNumberForm: ForwardRefRenderFunction<SerialNumberFormHandle, SerialNumberProps> = (props: SerialNumberProps) => {
  const trackers = useSelector((state: RootState) => state.trackers);
  const dispatch = useDispatch<AppDispatch>();

  const { formRef, index } = props;

  const { control, register, handleSubmit, trigger, setValue, formState: {errors} } = useForm<SerialNumber>(
    {
      defaultValues: {
        serialNumber: "",
        trackerId: 0
      }
  });

  const onSubmit: SubmitHandler<SerialNumber> = async data => {
    await dispatch(updateTrackerSerialNumber(data));
    props.onSuccess();
  };

  //permet d appeler le submit form depuis le composant parent
  useImperativeHandle(formRef, () => ({
    submitForm() {
        trigger()
        handleSubmit(onSubmit)();
      },
  }));

  useEffect(() => {
    setValue('serialNumber', trackers.data[index].serialNumber)
    setValue('trackerId', trackers.data[index].id)
  }, [props.index])

  return (
    <>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete={"off"}>
          <Box
            component="div"
            style={{marginTop: '0px', marginBottom: '-20px', padding: '0px'}}
          >
            <Controller
              name="serialNumber"
              control={control}
              render={({field})=>(
                <TextField
                  {...field}
                    id="SerialNumberTextField"
                    type="text"
                    variant="standard"
                    size="small"
                    color='primary'
                    style={{margin: '0px', padding: '0px', width: '90%'}}
                    required
                    error={!!(errors.serialNumber) }
                    {...register("serialNumber", {
                            required: "Le numéro de serie est obligatoire",
                            minLength: {
                                value: 3,
                                message: "Le numéro de serie doit contenir au moins 3 caractères"
                            }
                        })
                    }
                    fullWidth
                    helperText={(errors.serialNumber) ? errors.serialNumber.message : null}
                />
            )} />
          </Box>
        </form>
    </>
  );
}

export default React.forwardRef(SerialNumberForm);
