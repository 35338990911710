import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SynchronisedDiv from '../SynchronisedDiv';
import {useEffect, useState} from 'react';
import {forkJoin} from 'rxjs';
import {CardMedia, Checkbox, Grid} from '@mui/material';
import image from '../../../../../assets/ess/icon_ess.svg'
import {Ess} from "../../../../../interfaces/ess/Ess";
import {EssStatus} from "../../../../../interfaces/ess/EssStatus";
import apiV2 from '../../../../../service/api/ApiV2';
import AppCollapse from "../AppCollapse";
import { useAppDispatch } from '../../../../../store/hooks';
import { toggleEss } from '../../../../../store/scada/plant/PlantObjectsSlice';
import EssDetails from "./EssDetails";

type EssCardProps = {
    ess: Ess;
    isSelected: boolean;
}

export default function EssCard(props: EssCardProps) {
    const [essStatus, setEssStatus] = useState<EssStatus>();
    const dispatch = useAppDispatch();

    function toggleSelect(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(toggleEss({ id: props.ess.id, isSelected: event.target.checked }))
    }

    useEffect(() => {
        const status = forkJoin({
            essStatus: apiV2.essStatus(props.ess.id),
        });

        status.subscribe({
            next: (results) => {
                setEssStatus(results.essStatus[0]);
            },
        })
    }, [props.ess]);

    if (!essStatus) return <></>;
    return (
        <Card className='scada-card'>
            <Grid container sx={{pb: 0}}>
                <Grid item xs={2}>
                    <CardContent>
                        <CardMedia
                            className='scada-card-image'
                            component="img"
                            image={image}
                        />
                    </CardContent>
                </Grid>
                <Grid item xs={8}>
                    <CardContent sx={{'&:last-child': {pb: 1}}}>
                        <Typography component="div" className='scada-card-title'>
                            {props.ess.name}
                        </Typography>
                        <SynchronisedDiv isSynchronised={essStatus.isSynchronised}/>
                    </CardContent>
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        onChange={toggleSelect}
                        checked={props.isSelected}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CardContent sx={{ '&:last-child': { pt: 0, pb: 1 } }}>
                        <AppCollapse title="Détails">
                            <EssDetails ess={props.ess} />
                        </AppCollapse>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}