import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from "@mui/material/Typography";
import DataTableCheckpoint from '../DataTableCheckpoint'

import Grid from '@mui/material/Grid';

import InputComment from '../InputComment'

import { SoutirageChart } from './SoutirageChart';
import { CheckpointBlock } from '../../../../interfaces/control/CheckpointBlock';
import { Plant } from '../../../../interfaces/Plant';


interface propsInterface {
    checkpointBlock: CheckpointBlock;
    plant: Plant
}

export default function MeterCard(props: propsInterface) {
    const checkpointsFilteredBySoutirage = props.checkpointBlock.checkpoints.filter(x => x.name === 'Cohérence des courbes')
    let soutirageCheckpoint = null
    if (checkpointsFilteredBySoutirage.length === 1){
        soutirageCheckpoint = checkpointsFilteredBySoutirage[0]
    }

    return (
        <Card data-testid="card" className='custom-card' style={{ marginTop: 20, paddingBottom: 10, paddingRight: 5, paddingLeft: 5 }}>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography gutterBottom variant="h5" component="div">
                            {
                                (props.checkpointBlock.equipmentName === null) ? "Compteur" : props.checkpointBlock.equipmentName
                            }
                        </Typography>
                    </Grid>
                </Grid>
                {
                    (soutirageCheckpoint?.result?.data)
                    ?
                    <SoutirageChart data={soutirageCheckpoint.result?.data} plant={props.plant}/>
                    :
                    <></>
                }
                <DataTableCheckpoint data={props.checkpointBlock.checkpoints}></DataTableCheckpoint>
            </CardContent>
            <CardActions>
                <InputComment checkpointBlock={props.checkpointBlock}></InputComment>
            </CardActions>
        </Card>
    );
}