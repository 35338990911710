import { useTheme } from '@mui/material';
import { StatusType } from '../../../../interfaces/StatusType';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import { eventLevelColor } from '../AlarmService';

type StatusDivProps = {
    statusType: StatusType;
}

function eventLevelIcon(level: number) {
    if (level >= 30) return <WarningAmberIcon />;
    if (level >= 20) return <WarningAmberIcon />;
    if (level >= 10) return <CheckCircleOutlineIcon />;
    return <InfoIcon />;
}

export default function StatusDiv(props: StatusDivProps) {
    const theme = useTheme();
    const color = eventLevelColor(props.statusType.level, theme);
    const icon = eventLevelIcon(props.statusType.level);

    return <div style={{ color: color, display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ paddingLeft: 5 }}>{props.statusType.label}</span>
    </div>
}
