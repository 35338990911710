import React from 'react';

interface DocumentTypeViewProps {
  typeDeDocument: string | null;
  documentTypes: Array<DirectusFieldOption>;
}

interface DirectusFieldOption {
  text: string;
  value: string;
}

const DocumentTypeView: React.FC<DocumentTypeViewProps> = ({ typeDeDocument, documentTypes }) => {
  const documentType = documentTypes.find((docType) => docType.value === typeDeDocument);

  return (
    <>
      {documentType ? (
        <div>{documentType.text}</div>
      ) : (
        typeDeDocument
      )}
    </>
  );
};

export default DocumentTypeView;