import React, { useState, useEffect, useRef } from 'react'
import GoogleMapReact from 'google-map-react'
import { REACT_APP_GOOGLE_MAP_KEY } from '../../config'
import html2canvas from 'html2canvas'
import { Box } from '@mui/material'

interface MapAddressProps {
  latitude: number | null
  longitude: number | null
  precision: number
  onChange: (position: { lat: number; lng: number }) => void
  mapHeight: string
  mapWidth: string
  onCapture: (data: HTMLCanvasElement) => void
}

const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAP_KEY;

const MapAddress: React.FC<MapAddressProps> = (props: MapAddressProps) => {
  const mapHeight = props.mapHeight
  const mapWidth = props.mapWidth
  const mapContainerRef = useRef<HTMLDivElement | null>(null)

  const roundToPrecision = (value: number, precision: number) => {
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
  };

  const [markerPosition, setMarkerPosition] = useState({
    lat: roundToPrecision(props.latitude || 0, props.precision),
    lng: roundToPrecision(props.longitude || 0, props.precision)
  });

  const mapRef = useRef<google.maps.Map | null>(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (props.latitude === null && props.longitude === null) {
      return
    }
    setMarkerPosition({
      lat: roundToPrecision(props.latitude as number, props.precision),
      lng: roundToPrecision(props.longitude as number, props.precision)
    });
  }, [props.latitude, props.longitude, props.precision]);

  const handleApiLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    mapRef.current = map;
    markerRef.current = new maps.Marker({
      position: markerPosition,
      map,
      draggable: true,
    });

    markerRef.current.addListener('dragend', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const newPosition = {
          lat: roundToPrecision(event.latLng.lat(), props.precision),
          lng: roundToPrecision(event.latLng.lng(), props.precision),
        };
        setMarkerPosition(newPosition);

        props.onChange(newPosition);
      }
    });
  };

  useEffect(() => {
    if (!markerRef.current) {
      return
    }
    markerRef.current.setPosition(markerPosition);
  }, [markerPosition]);

  const defaultProps = {
    center: {
      lat: 0,
      lng: 0
    },
    zoom: 2
  };

  const doCapture = () => {
    if (!mapContainerRef.current) {
      return
    }

    const mapContainer = mapContainerRef.current;

    html2canvas(mapContainer, {
      backgroundColor: null,
      useCORS: true
    }).then((canvas: HTMLCanvasElement) => {
      props.onCapture(canvas);
    })
  }

  return (
    <Box
      ref={mapContainerRef}
      sx={{
        height: mapHeight,
        width: mapWidth,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY, libraries: ['core', 'places'] }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={props.latitude && props.longitude ? { lat: props.latitude, lng: props.longitude } : defaultProps.center}
        zoom={props.latitude && props.longitude ? 13 : defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
        onTilesLoaded={() => doCapture()}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          mapTypeId: 'satellite',
        }}
      />
    </Box>
  )
};

export default MapAddress;
