import { ScriptableLineSegmentContext } from "chart.js";
import { ImmersionHeaterAlarm } from "../../../interfaces/ImmersionHeaterAlarm";
import { TrackerAlarm } from "../../../interfaces/TrackerAlarm";
import { Theme } from '@mui/material';
import dayjs, { Dayjs } from "dayjs";

export function eventLevelColor(level: number, theme: Theme): string {
    if (level >= 30) return theme.palette.error.main;
    if (level >= 20) return theme.palette.warning.main;
    if (level >= 10) return theme.palette.success.main;
    return theme.palette.notSynchronised;
}

export function getCurrentAlarmIndexesFromReference(trackerAlarms: (TrackerAlarm | ImmersionHeaterAlarm)[], referencePattern: string): number[] {
    const res = []
    for (let i = 0; i < trackerAlarms.length; i++) {
        if (trackerAlarms[i].reference.includes(referencePattern) && trackerAlarms[i].endDate === null) {
            res.push(i)
        }
    }
    return res
}

/**
 * This function is used to plot event charts.
 * It associates an Y value (from 2 to n) to an alarm reference -> this way each alarm reference appear on a different line
 *
 * @param {TrackerAlarm[]} alarmPayload
 * @returns {{ [key: string]: number; }}
 */
export function mappingAlarmsToYvalues(alarmPayload: (TrackerAlarm | ImmersionHeaterAlarm)[]): { [key: string]: number } {
    // list all alarms for one tracker (we remove duplicates to associate only one y value to each alarm)
    const alarms = alarmPayload.map(x => x.reference).filter((item, pos, self) => self.indexOf(item) === pos);
    const alarmYValues: { [key: string]: number } = {}
    let yValue = 2;
    for (const alarm of alarms) {
        alarmYValues[alarm] = yValue++;
    }
    return alarmYValues;
}

/**
 * This function is used to plot event charts.
 * It creates a timeserie given an alarm (i.e an object with a begin and a end)
 *
 * @param {string[]} expectedDates
 * @param {TrackerAlarm | ImmersionHeaterAlarm} alarm
 * @param {number} yValue
 * @returns {(number | null)}
 */
export function createAlarmTimeserie(expectedDates: string[], alarm: TrackerAlarm | ImmersionHeaterAlarm, yValue: number): {x: number, y: number}[] {
    let expectedDatesCopy = [...expectedDates];
    // startDate and endDate are the dates in the url
    const startDate = expectedDatesCopy[0]
    const endDate = expectedDatesCopy[expectedDatesCopy.length - 1]

    const now = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');
    const alarmEndDate = alarm.endDate ?? now;
    // only keep the dates that are between the begin and the end of the alarm
    expectedDatesCopy = expectedDatesCopy.filter((date) => (date > alarm.beginDate) && (date < alarmEndDate))
    // then add the begin and the end of the alarm if it is in the interval of the startDate and endDate
    if (alarm.beginDate > startDate) {
        expectedDatesCopy.unshift(alarm.beginDate)
    }
    if (alarmEndDate < endDate) {
        expectedDatesCopy.push(alarmEndDate)
    }

    return expectedDatesCopy.map(date => {
        return {
            x: dayjs(date).valueOf(),
            y: yValue
        }
    })
}

/**
 * This function is used to plot event charts.
 * It handles the display of the labels of the alarms on the Y axis
 *
 * @param {{ [key: string]: number }} alarmYvalues
 * @returns {{ [key: string]: {} }}
 */
export function createYLabels(alarmYvalues: { [key: string]: number }, date: Dayjs, theme: Theme): { [key: string]: object } {
    // function used to display Y label on chart
    const res: { [key: string]: object } = {};
    for (const alarmReference in alarmYvalues) {
        res[alarmReference] = {
            type: 'label',
            xValue: date.format('YYYY-MM-DD HH:mm:ss'),
            yValue: alarmYvalues[alarmReference],
            yAdjust: -12, // emmpirical value used to adjust the label on the line of the alarm
            content: alarmReference,
            position: 'start',
            font: {
                size: 12,
                weight: 'bold'
            },
            color: (theme.palette.mode === 'dark') ? '#f3f1f1' : 'black'
        }
    }
    return res;
}

/**
 * This function is used to plot event charts.
 * It tells if we should plot a dashed line for event charts. (cf chartJS borderDash function).
 * The rule is the following :
 * we plot a dashed line for current alarms (i.e with no end) when a disconnection alarm is also present
 *
 * @export
 * @param {ScriptableLineSegmentContext} ctx context of line segment cf chartJS borderDash function
 * @param {string[]} expectedDates array of dates that will be used to plot the chart
 * @param {(TrackerAlarm|ImmersionHeaterAlarm)[]} alarms array of alarms
 * @param {number[]} disconnectAlarmIndexes array of index (in the alarms array) of disconnected alarms that have no end
 * @param {number} alarmIndex index (in the alarms array) of an alarm -> this is the alarm that can possibiliy be plot dashed
 * @returns {(number[] | undefined)}
 */
export function borderDashBasedOnDisconnectionAlarms(
    ctx: ScriptableLineSegmentContext,
    expectedDates: string[],
    alarms: (TrackerAlarm | ImmersionHeaterAlarm)[],
    disconnectionAlarmIndexes: number[],
    alarmIndex: number,
): number[] | undefined {
    // no disconnection alarm so no dashed lines
    if (disconnectionAlarmIndexes.length === 0) {
        return undefined
    }
    // never put dashed line for disconnetion alarms
    if (alarms[alarmIndex].reference.includes('DISCONNECT')) {
        return undefined
    }
    // never put dashed line for alarms with end date
    if (alarms[alarmIndex].endDate !== null) {
        return undefined
    }
    for (const disconnectAlarmIndex of disconnectionAlarmIndexes) {
        // if alarm does not happen during a current disconnection alarm then not dashed line
        if (expectedDates[ctx.p0DataIndex] < alarms[disconnectAlarmIndex].beginDate) {
            return undefined
        }
    }
    return [1.5, 1.5]
}
