import {Grid} from "@mui/material";
import CardRow from "../CardRow";
import {formatDate} from "../../../../../service/FormatDate";
import {Plant} from "../../../../../interfaces/Plant";

interface propsInterface {
    plant: Plant;
}

export default function PlantDetails(props: propsInterface) {
    return (
        <Grid container>
            <CardRow label="Identifiant" data-testid="idPlant">
                {props.plant.id}
            </CardRow>
            <CardRow label="Mise en route">
                {formatDate(props.plant.operationDate)}
            </CardRow>
            <CardRow label="Bridage activé">
                {props.plant.restrictedPower ? "Oui" : (props.plant.restrictedPower === false ? "Non" : "")}
            </CardRow>
            {props.plant.restrictedPower &&
            <CardRow label="Valeur de bridage">
                {props.plant.restrictedValue !== null ? props.plant.restrictedValue / 1000 + " kW" : ""}
            </CardRow>}
        </Grid>
    );
}