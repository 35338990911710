import { Autocomplete, TextField } from "@mui/material";

type optionItem = {id: string | number, label: string}

type onChangeInput = (number | string) | (number | string)[] | null

type FilterProps = {
    options: optionItem[],
    label: string,
    onChange: (value: onChangeInput) => void,
    value: string | number | null // value is the id of item that is selected
    style?: React.CSSProperties
}
export default function Filter(props: FilterProps) {

    function updateSelectedValues(value: optionItem | null): void {
        if (value === null) {
            props.onChange(value)
            return
        }

        props.onChange(value.id)
    }
    // convert the props.defaultValue to "options" format
    function valueToOptions(defaultValue: string | number | null, options: optionItem[]): null| optionItem {
        if (defaultValue === null) return null
        return options.filter(option => defaultValue === option.id)[0]
    }

    return (
        <Autocomplete
            disablePortal
            sx={props.style}
            options={props.options}
            value={valueToOptions(props.value, props.options)}
            renderInput={(params) => <TextField {...params} label={props.label}  size="small" onClick={e => {e.stopPropagation()}}/>}
            onChange={(_, value) => {updateSelectedValues(value)}}
            onClose={e => {e.stopPropagation()}}
            slotProps={{
                clearIndicator: {
                    sx: {
                        visibility: 'visible',
                    },
                },
            }}
        />
    );
}