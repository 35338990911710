import { Feature } from "./Feature";

export enum StatusEnum {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    ARCHIVED = 'archived'
}

export interface Release {
    id: number;
    status: StatusEnum;
    name: string;
    published: string;
    features: Feature[];
}
