import {useTheme} from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import ReleasesCard from './ReleasesCard';
import HelpCard from './HelpCard';

function HomeView() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
      <Grid item xs={12}>
        <Card className='custom-card' data-testid="homeCard">
          <CardContent>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" component="div" style={{fontWeight: "bold"}}>
                  Bienvenue dans votre espace partenaire !
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <p>
                  Cette plateforme centralise les services numériques nécessaires au dimensionnement, à l’installation, à la maintenance et à la supervision des installations OKwind et Lumioo.
                </p>
                <Typography gutterBottom variant="subtitle1" component="div" style={{fontWeight: "bold"}}>
                  Pourquoi "espace partenaire" ?
                </Typography>
                <p>
                  Car cette plateforme a été pensée pour s'adapter aux évolutions futures de nos organisations. <br></br>
                  Elle peut être utilisée par nos collaborateurs OKwind et Lumioo mais également des sociétés partenaires.
                </p>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={12} md={6} style={{ marginTop: '20px',  paddingRight: (isMobile) ? '0px' : '10px'}}>
        <ReleasesCard></ReleasesCard>
      </Grid>
      <Grid item sm={12} md={6} style={{ marginTop: '20px',  paddingLeft: (isMobile) ? '0px' : '10px'}}>
        <HelpCard></HelpCard>
      </Grid>
    </Grid>
  );
}

export default HomeView;
