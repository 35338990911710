import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from "react-redux";
import {useTheme} from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import CommentIcon from '@mui/icons-material/Comment';

import { BehaviorSubject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { AppDispatch } from "../../../store/store";
import { updateCheckpointBlockResultComment } from '../../../store/control/checkpointsBlock.store';

import { CheckpointBlock } from '../../../interfaces/control/CheckpointBlock';

interface propsInterface {
    checkpointBlock: CheckpointBlock;
}

export default function InputComment(props: propsInterface) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const dispatch = useDispatch<AppDispatch>();
    const [commentValue, setCommentValue] = useState<string>("");

    const comment$ = useMemo(() => new BehaviorSubject(""), []);

    const handleChangeComment = (comment: string): void => {
        const temporayCheckpointBlockResult = {...props.checkpointBlock}
        temporayCheckpointBlockResult.comment = comment
        dispatch(updateCheckpointBlockResultComment(temporayCheckpointBlockResult))
    }

    useEffect(() => {
        if(props.checkpointBlock.comment){
          setCommentValue(props.checkpointBlock.comment)
        }
      }, [props]);

    useEffect(() => {
        if(commentValue.length >= 1 && props.checkpointBlock.comment !== commentValue){
          const subscription = comment$
          .pipe(debounceTime(400))
          .subscribe(() => {handleChangeComment(commentValue)});
          return () => subscription.unsubscribe();
        }
        if(commentValue.length === 0 && props.checkpointBlock.id !== null) handleChangeComment(commentValue)
      }, [commentValue, comment$]);

    return (
        <div>
            <Box
                sx={{
                    width: (isMobile) ? '100%' : 500,
                }}
                >
                <TextField
                    data-testid="commentInput" 
                    id="commentInput"
                    style={{ marginTop: -5, marginLeft: (isMobile) ? 0 : 10, width: (isMobile) ? '100%' : 500}}
                    disabled={(props.checkpointBlock.id === null)}
                    value={commentValue}
                    placeholder="Commentaires"
                    helperText={(props.checkpointBlock.id === null) ? 'Vous devez executer les points de contrôles avant d\'ecrire un commentaire' : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCommentValue(event.target.value)}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CommentIcon />
                        </InputAdornment>
                    ),
                    }}
                />
            </Box>
        </div>
    );
}