import authenticatedApi from "../AuthenticatedApi";
import {Agency} from "../../interfaces/Agency";
import {User} from "../../interfaces/User";
import {ClientSearch} from "../../interfaces/client/ClientSearch";
import {Client} from "../../interfaces/client/Client";
import {AlarmType} from "../../interfaces/AlarmType";
import {ImmersionHeater} from "../../interfaces/ImmersionHeater";
import {Plant} from "../../interfaces/Plant";
import {CreatePlant, UpdatePlant} from "../../interfaces/plant/Plant";
import {TrackerType} from "../../interfaces/plant/TrackerType";
import {PowerImmersionHeater} from "../../interfaces/PowerImmersionHeater";
import {PowerInverter} from "../../interfaces/PowerInverter";
import {PowerPlant} from "../../interfaces/PowerPlant";
import {Tracker} from "../../interfaces/Tracker";
import {SerialNumber} from '../../interfaces/tracker/SerialNumber';
import {Weather} from "../../interfaces/Weather";
import {ListUUIDTrackerAlarmTypeInterface} from "../../app/scada/remote/ListUUIDTrackerAlarmTypeInterface";
import {TrackerWind} from "../../interfaces/TrackerWind";
import {TrackerPosition} from "../../interfaces/TrackerPosition";
import {Dayjs} from "dayjs";
import {TrackerAlarm} from "../../interfaces/TrackerAlarm";
import {ImmersionHeaterAlarm} from "../../interfaces/ImmersionHeaterAlarm";
import {PlantSearch} from "../../interfaces/PlantSearch";
import {PlantStatus} from "../../interfaces/PlantStatus";
import {TrackerStatus} from "../../interfaces/TrackerStatus";
import {ImmersionHeaterStatus} from "../../interfaces/ImmersionHeaterStatus";
import {MeterStatus} from "../../interfaces/MeterStatus";
import {CurrentAuthorization} from "../../interfaces/CurrentAuthorization";
import {GetAlarmNotViewedBySav} from "../../interfaces/GetAlarmNotViewedBySav";
import {Ess} from "../../interfaces/ess/Ess";
import {EssStatus} from "../../interfaces/ess/EssStatus";
import UserModel from "../../app/platform/users/model";

import { MapOfPlantStatusesItem } from "../../interfaces/MapOfPlantStatusesItem";
import { ListTrackersByAlarmItem } from "../../interfaces/ListTrackersByAlarmItem";
import { PlantStatusType } from "../../interfaces/PlantStatusTypes";
import { REACT_APP_API_V2_URL } from "../../config";
import { Meter } from "../../interfaces/Meter";
import { TrackerInternetStatus } from "../../interfaces/TrackerInternetStatus";
import { NumberPvPanel } from "../../interfaces/tracker/NumberPvPanel";
import {SetPvPanel} from "../../interfaces/tracker/SetPvPanel";
import {PvPanelType} from "../../interfaces/tracker/PvPanelType";

const baseUrl = REACT_APP_API_V2_URL;
const powerPlantMinutesEndpoint = 'v2/human/power_plant_minutes';
const powerInverterMinutesEndpoint = 'v2/human/power_inverter_minutes';
const trackersEndpoint = 'v2/human/trackers';
const meterEndpoint = 'v2/human/meters';
const plantEndpoint = 'v2/human/plants';
const agenciesEndpoint = 'v2/human/agencies';
const immersionHeatersEndpoint = 'v2/human/immersion_heaters';
const powerImmersionHeaterMinutesEndpoint = 'v2/human/power_immersion_heater_minutes';
const weathersEndpoint = 'v2/human/weathers';
const trackersTypes = 'v2/human/trackers_types';
const trackerAlarmTypeEndpoint = 'v2/human/reference/alarm/tracker_alarm_types';
const restartTrackingByAlarmEndpoint = 'v2/human/remote/restart_tracking_by_alarm';
const trackerWindSpeedsEndpoint = 'v2/human/tracker_wind_speeds';
const trackerPositionsEndpoint = 'v2/human/tracker_positions';
const trackerAlarmsEndpoint = 'v2/human/tracker_alarms';
const immersionHeaterAlarmsEndpoint = 'v2/human/immersion_heater_alarms';
const searchPlantsEndpoint = 'v2/human/search_plants';
const searchUsersEndpoint = 'v2/human/search_users';
const searchClientsEndpoint = 'v2/human/search_clients';
const plantStatusEndpoint = 'v2/human/scada/plant_full_statuses';
const trackerStatusEndpoint = 'v2/human/scada/tracker_full_statuses';
const immersionHeaterStatusEndpoint = 'v2/human/scada/immersion_heater_full_statuses';
const meterStatusEndpoint = 'v2/human/scada/meter_full_statuses';
const currentAuthorizationEndpoint = 'v2/human/current_authorization'
const getAlarmNotViewedBySavEndpoint = 'v2/human/get_alarm_not_viewed_by_sav'
const setAlarmSavStatusAsViewedEndpoint = 'v2/human/set_alarm_sav_status_as_viewed'
const restartTrackingEndpoint = 'v2/human/remote/restart_tracking'
const startSSHEndpoint = 'v2/human/remote/start_ssh'
const stopSSHEndpoint = 'v2/human/remote/stop_ssh'
const startFlatEndpoint = 'v2/human/remote/start_flat'
const stopFlatEndpoint = 'v2/human/remote/stop_flat'
const trackerAlarmEndEndpoint = 'v2/human/tracker_alarm_end_collection'
const graphMigrationEndpoint = 'v2/human/graph-migration'
const purgeStatusEndpoint = 'v2/human/purge_status'
const nominalPowerComputeEndpoint = 'v2/human/nominal-power-compute'
const downloadConfiguratorEndpoint = 'v2/human/software/configurator'
const essEndpoint = 'v2/human/ess/ess';
const essStatusEndpoint = 'v2/human/ess/status';
const installatorsEndpoint = 'v2/human/user/installators';
const createUserEndpoint = 'v2/human/user/create';
const updateUserEndpoint = 'v2/human/user/update';
const deleteUserEndpoint = 'v2/human/user/delete';
const createClientEndpoint = 'v2/human/client/create';
const updateClientEndpoint = 'v2/human/client/update';
const deleteClientEndpoint = 'v2/human/client/delete';
const detailClientEndpoint = 'v2/human/client/detail';
const mapOfPlantStatusesEndpoint = 'v2/human/scada/map_of_plant_full_statuses';
const listTrackersByAlarmEndpoint = 'v2/human/scada/list_trackers_by_alarm'
const plantStatusTypesEndpoint = 'v2/human/scada/plant_status_types'
const createPlantEndpoint = 'v2/human/plant/create';
const updatePlantEndpoint = 'v2/human/plant/update';
const deletePlantEndpoint = 'v2/human/plant/delete';
const detailPlantEndpoint = 'v2/human/plant/detail';
const deleteTrackerEndpoint = 'v2/human/tracker/delete';
const trackerInternetStatusEndpoint = 'v2/human/tracker/{trackerId}/internet_status';
const updateTrackerSerialNumberEndpoint = 'v2/human/set_tracker_serial_number';
const updateTrackerNumberPvPanelEndpoint = 'v2/human/set_tracker_number_pv_panel';
const updateTrackerPvPanelEndpoint = 'v2/human/set_tracker_pvpanel';
const pvPanelTypesEndpoint = 'v2/human/reference/tracker/pv_panel_types';

const apiV2 = {
    trackers: async function(plantId: number): Promise<Array<Tracker>> {
        const url = `${baseUrl}/${trackersEndpoint}?plant=${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    meter: async function(plantId: number): Promise<Meter> {
        const url = `${baseUrl}/${meterEndpoint}?plant=${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    plant: async function(plantId: number): Promise<Plant> {
        const url = `${baseUrl}/${plantEndpoint}/${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    immersionHeaters: async function(plantId: number): Promise<Array<ImmersionHeater>> {
        const url = `${baseUrl}/${immersionHeatersEndpoint}?plant=/v2/human/plants/${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    powerInverter: async function(startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<PowerInverter>> {
        const url = `${baseUrl}/${powerInverterMinutesEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&tracker=%2Fv2%2Fhuman%2Ftrackers%2F${trackerId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    powerImmersionHeater: async function(startDate: Dayjs, endDate: Dayjs, immersionHeaterId: string): Promise<Array<PowerImmersionHeater>> {
        const url = `${baseUrl}/${powerImmersionHeaterMinutesEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&immersionHeater=%2Fv2%2Fhuman%2Fimmersion_heaters%2F${immersionHeaterId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    powerPlant: async function(startDate: Dayjs, endDate: Dayjs, plantId: number): Promise<Array<PowerPlant>> {
        const url = `${baseUrl}/${powerPlantMinutesEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&plant=%2Fv2%2Fhuman%2Fplants%2F${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    weather: async function(startDate: Dayjs, endDate: Dayjs, plantId: number): Promise<Array<Weather>> {
        const url = `${baseUrl}/${weathersEndpoint}?begin=${startDate.format('YYYY-MM-DD HH:mm:ss')}&end=${endDate.format('YYYY-MM-DD HH:mm:ss')}&plant=${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    trackerAlarmType: async function(): Promise<Array<AlarmType>> {
        return authenticatedApi.get(`${baseUrl}/${trackerAlarmTypeEndpoint}`)
            .then(response =>response.json())
            .then(data => data);
    },
    restartByAlarm: async function(listSelectedTrackerAlarmType: ListUUIDTrackerAlarmTypeInterface): Promise<Response> {
        const url = `${baseUrl}/${restartTrackingByAlarmEndpoint}`
        return authenticatedApi.post(url, listSelectedTrackerAlarmType)
    },
    trackerWindSpeed: async function(startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerWind>> {
        const url = `${baseUrl}/${trackerWindSpeedsEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&trackerId=${trackerId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    trackerPositions: async function(startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerPosition>> {
        const url = `${baseUrl}/${trackerPositionsEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&trackerId=${trackerId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    trackerAlarms: async function(startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerAlarm>> {
        const url = `${baseUrl}/${trackerAlarmsEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&trackerId=${trackerId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    immersionHeaterAlarms: async function(startDate: Dayjs, endDate: Dayjs, immersionHeaterId: string): Promise<Array<ImmersionHeaterAlarm>> {
        const url = `${baseUrl}/${immersionHeaterAlarmsEndpoint}?date[after]=${startDate.format('YYYY-MM-DD HH:mm:ss')}&date[before]=${endDate.format('YYYY-MM-DD HH:mm:ss')}&immersionHeaterId=${immersionHeaterId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    searchPlants: async function(search: string): Promise<Array<PlantSearch>> {
        const url = `${baseUrl}/${searchPlantsEndpoint}?search=${search}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    searchPlantById: async function(id: number): Promise<Array<PlantSearch>> {
        const url = `${baseUrl}/${searchPlantsEndpoint}?id=${id}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    searchUsers: async function(search: string): Promise<Array<User>> {
        const url = `${baseUrl}/${searchUsersEndpoint}?search=${search}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    searchClients: async function(search: string): Promise<Array<ClientSearch>> {
        const url = `${baseUrl}/${searchClientsEndpoint}?search=${search}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    plantStatus: async function(plantId: number): Promise<PlantStatus> {
        const url = `${baseUrl}/${plantStatusEndpoint}/${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    trackerStatus: async function(trackerId: number): Promise<TrackerStatus> {
        const url = `${baseUrl}/${trackerStatusEndpoint}/${trackerId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    immersionHeaterStatus: async function(immersionHeaterId: string): Promise<ImmersionHeaterStatus> {
        const url = `${baseUrl}/${immersionHeaterStatusEndpoint}/${immersionHeaterId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    meterStatus: async function(meterId: number): Promise<MeterStatus> {
        const url = `${baseUrl}/${meterStatusEndpoint}/${meterId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    currentAuthorization: async function(): Promise<CurrentAuthorization> {
        const url = `${baseUrl}/${currentAuthorizationEndpoint}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    getAlarmNotViewedBySav: async function(plantId?: number): Promise<Array<GetAlarmNotViewedBySav>> {
        let url = `${baseUrl}/${getAlarmNotViewedBySavEndpoint}`;
        if (plantId){
            url += `?plant=${plantId}`
        }
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    setAlarmSavStatusAsViewed: async function(alarmId: string): Promise<Response> {
        const url = `${baseUrl}/${setAlarmSavStatusAsViewedEndpoint}`;
        return authenticatedApi.post(url, {id: alarmId});
    },
    restartTracking: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${restartTrackingEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    startSSH: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${startSSHEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    stopSSH: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${stopSSHEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    startFlat: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${startFlatEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    stopFlat: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${stopFlatEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    trackerAlarmEnd: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${trackerAlarmEndEndpoint}`;
        return authenticatedApi.post(url, {trackerId: trackerId});
    },
    graphMigration: async function(plantId: number, date: string, mode: number): Promise<Response> {
        const url = `${baseUrl}/${graphMigrationEndpoint}`;
        return authenticatedApi.post(url, {plant: `/v2/human/plants/${plantId}`, date: date, mode: mode});
    },
    purgeStatus: async function(plantId: number): Promise<Response> {
        const url = `${baseUrl}/${purgeStatusEndpoint}`;
        return authenticatedApi.post(url, {plant: `/v2/human/plants/${plantId}`});
    },
    nominalPowerCompute: async function(plantId: number): Promise<Response> {
        const url = `${baseUrl}/${nominalPowerComputeEndpoint}`;
        return authenticatedApi.post(url, {plant: `/v2/human/plants/${plantId}`});
    },
    installators: async function(): Promise<Array<User>> {
        const url = `${baseUrl}/${installatorsEndpoint}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    createUser: async function(user: UserModel): Promise<Response> {
        const url = `${baseUrl}/${createUserEndpoint}`;
        return await authenticatedApi.post(url, user);
    },
    updateUser: async function(user: UserModel): Promise<Response> {
        const url = `${baseUrl}/${updateUserEndpoint}`;
        return await authenticatedApi.post(url, user);
    },
    deleteUser: async function(userId: number): Promise<Response> {
        const url = `${baseUrl}/${deleteUserEndpoint}`;
        return await authenticatedApi.post(url, {userId: userId});
    },
    detailClient: async function(clientId: number): Promise<Client> {
        const url = `${baseUrl}/${detailClientEndpoint}/${clientId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    createClient: async function(client: Client): Promise<Response> {
        const url = `${baseUrl}/${createClientEndpoint}`;
        return await authenticatedApi.post(url, client);
    },
    updateClient: async function(client: Client): Promise<Response> {
        const url = `${baseUrl}/${updateClientEndpoint}`;
        return await authenticatedApi.post(url, client);
    },
    deleteClient: async function(clientId: number): Promise<Response> {
        const url = `${baseUrl}/${deleteClientEndpoint}`;
        return await authenticatedApi.post(url, {clientId: clientId});
    },
    detailPlant: async function(plantId: number): Promise<UpdatePlant> {
        const url = `${baseUrl}/${detailPlantEndpoint}/${plantId}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    createPlant: async function(plant: CreatePlant): Promise<Response> {
        const url = `${baseUrl}/${createPlantEndpoint}`;
        return await authenticatedApi.post(url, plant);
    },
    updatePlant: async function(plant: UpdatePlant): Promise<Response> {
        const url = `${baseUrl}/${updatePlantEndpoint}`;
        return await authenticatedApi.post(url, plant);
    },
    deletePlant: async function(plantId: number): Promise<Response> {
        const url = `${baseUrl}/${deletePlantEndpoint}`;
        return await authenticatedApi.post(url, {plantId: plantId});
    },
    deleteTracker: async function(trackerId: number): Promise<Response> {
        const url = `${baseUrl}/${deleteTrackerEndpoint}`;
        return await authenticatedApi.post(url, {trackerId: trackerId});
    },
    updateTrackerSerialNumber: async function(serialNumber: SerialNumber): Promise<Response> {
        const url = `${baseUrl}/${updateTrackerSerialNumberEndpoint}`;
        return await authenticatedApi.post(url, serialNumber, false);
    },
    updateTrackerNumberPvPanel: async function(numberPvPanel: NumberPvPanel): Promise<Response> {
        const url = `${baseUrl}/${updateTrackerNumberPvPanelEndpoint}`;
        return await authenticatedApi.post(url, numberPvPanel, false);
    },
    updateTrackerPvPanel: async (setPvPanelRequest: SetPvPanel) => {
        return await authenticatedApi.post(`${baseUrl}/${updateTrackerPvPanelEndpoint}`, setPvPanelRequest, false);
    },
    trackerTypes: async function(): Promise<Array<TrackerType>> {
        const url = `${baseUrl}/${trackersTypes}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    ess: async function(plantId: number): Promise<Array<Ess>> {
        const url = `${baseUrl}/${essEndpoint}?plant=${plantId}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    essStatus: async function(essId: string): Promise<Array<EssStatus>> {
        const url = `${baseUrl}/${essStatusEndpoint}/${essId}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    agencies: async function(): Promise<Array<Agency>> {
        const url = `${baseUrl}/${agenciesEndpoint}`;
        const response = await authenticatedApi.get(url);
        const data = response.json();
        return data;
    },
    mapOfPlantStatuses: async function (
        inAgencies: number[],
        notInPlantStatusTypes: string[],
        notSynchronised: boolean,
        startOperationDate: string | null,
        endOperationDate: string | null,
        savStatus: string | null,
        ): Promise<Array<MapOfPlantStatusesItem>> {
        const inAgenciesFilter = inAgencies.length > 0 ? `inAgencies=${inAgencies.toString()}` : null
        const notInPlantStatusTypesFilter = notInPlantStatusTypes.length > 0 ? `notInPlantStatusTypes=${notInPlantStatusTypes.toString()}` : null
        const notSynchronisedFilter = notSynchronised === false ? `notSynchronised=false` : null
        const startOperationDateFilter = startOperationDate !== null ? `date[startOperationDate]=${startOperationDate}` : null
        const endOperationDateFilter = endOperationDate !== null ? `date[endOperationDate]=${endOperationDate}` : null
        const savStatusFilter = savStatus !== null ? `savStatus=${savStatus}` : null
        const filters = [inAgenciesFilter, notInPlantStatusTypesFilter, notSynchronisedFilter, startOperationDateFilter, endOperationDateFilter, savStatusFilter]
            .filter(x => x !== null)
            .join('&')
        const url = `${baseUrl}/${mapOfPlantStatusesEndpoint}?${filters}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    listTrackersByAlarm: async function(): Promise<Array<ListTrackersByAlarmItem>> {
        const url = `${baseUrl}/${listTrackersByAlarmEndpoint}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    plantStatusTypes: async function(): Promise<Array<PlantStatusType>> {
        const url = `${baseUrl}/${plantStatusTypesEndpoint}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    trackerInternetStatus: async function(startDate: Dayjs, endDate: Dayjs, trackerId: number): Promise<Array<TrackerInternetStatus>> {
        const url = `${baseUrl}/${trackerInternetStatusEndpoint.replace('{trackerId}', trackerId.toString())}?begin=${startDate.format('YYYY-MM-DD HH:mm:ss')}&end=${endDate.format('YYYY-MM-DD HH:mm:ss')}`;
        const response = await authenticatedApi.get(url);
        return response.json();
    },
    downloadUrlConfigurator: function(os: string): string{
        const url = `${baseUrl}/${downloadConfiguratorEndpoint}/${os}`;
        return url;
    },
    getPvPanelTypes: async (): Promise<PvPanelType[]> => {
        const response = await authenticatedApi.get(`${baseUrl}/${pvPanelTypesEndpoint}`);
        return response.json();
    }
}

export default apiV2;