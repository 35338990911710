import { Grid } from '@mui/material'
import { SelectUser } from './component/SelectUser'
import { useState } from 'react';
import { DatePicker } from './component/DatePicker';
import { Dayjs } from 'dayjs';
import SearchTextField from '../../../component/form/SearchTextField';

export interface FilterInterface {
  userId: string | null
  search: string
  startDate: Dayjs | null
  endDate: Dayjs | null
}

interface Props {
  onChange: CallableFunction
}

export default function SimulationsFilter(props: Props) {
  const [filter, setFilter] = useState<FilterInterface>({
    userId: null,
    search: '',
    startDate: null,
    endDate: null,
  });

  const userChange = function (userId: string | null): void {
    filter.userId = userId
    setFilter(filter)
    props.onChange(filter)
  }

  const searchChange = function (search: string): void {
    filter.search = search
    setFilter(filter)
    props.onChange(filter)
  }

  const startDateChange = function (startDate: Dayjs): void {
    filter.startDate = startDate
    setFilter(filter)
    props.onChange(filter)
  }

  const endDateChange = function (endDate: Dayjs): void {
    filter.endDate = endDate
    setFilter(filter)
    props.onChange(filter)
  }

  return (
    <Grid container sx={{ backgroundColor: 'transparent'}}>
      <Grid style={{marginTop: '-5px'}} xs={12} sm={12} md={6} lg={3} item>
        <SearchTextField
          value={filter.search}
          notifyOnClear={false}
          width={'305px'}
          placeholder="Par Id, nom étude, adresse..."
          onChange={searchChange}
        ></SearchTextField>
      </Grid>
      <Grid style={{padding: '10px'}} xs={12} sm={12} md={6} lg={2} item>
        <SelectUser onChange={userChange} />
      </Grid>
      <Grid style={{padding: '10px'}} xs={12} sm={12} md={6} lg={2} item>
        <DatePicker label='Du' onChange={startDateChange}/>
      </Grid>
      <Grid style={{padding: '10px'}} xs={12} sm={12} md={6} lg={2} item>
        <DatePicker label='Au' onChange={endDateChange}/>
      </Grid>
    </Grid>
  )
}
