
export const isProduction = process.env.NODE_ENV === 'production';

const getEnv = (key: string): string => {
    return isProduction ? key : process.env[key.replace('_TO_REPLACE','')] ?? '';
}

export const REACT_APP_KEYCLOAK_URL = getEnv('REACT_APP_KEYCLOAK_URL_TO_REPLACE');
export const REACT_APP_USE_KEYCLOAK_IN_DEV = getEnv('REACT_APP_USE_KEYCLOAK_IN_DEV_TO_REPLACE');
export const REACT_APP_API_CONTROL_URL = getEnv('REACT_APP_API_CONTROL_URL_TO_REPLACE');
export const REACT_APP_API_SIZEA_URL = getEnv('REACT_APP_API_SIZEA_URL_TO_REPLACE');
export const REACT_APP_API_V2_URL = getEnv('REACT_APP_API_V2_URL_TO_REPLACE');
export const REACT_APP_MERCURE_URL = getEnv('REACT_APP_MERCURE_URL_TO_REPLACE');
export const REACT_APP_KEYCLOAK_REALM = getEnv('REACT_APP_KEYCLOAK_REALM_TO_REPLACE');
export const REACT_APP_KEYCLOAK_CLIENT_ID = getEnv('REACT_APP_KEYCLOAK_CLIENT_ID_TO_REPLACE');
export const REACT_APP_GOOGLE_MAP_KEY = getEnv('REACT_APP_GOOGLE_MAP_KEY_TO_REPLACE');
export const REACT_APP_GOOGLE_ANALYTICS_ID = getEnv('REACT_APP_GOOGLE_ANALYTICS_ID_TO_REPLACE');
export const REACT_APP_APP_CLIENT_URL = getEnv('REACT_APP_APP_CLIENT_URL_TO_REPLACE');
export const REACT_APP_ADMIN_CLIENT_V1_URL = getEnv('REACT_APP_ADMIN_CLIENT_V1_URL_TO_REPLACE');
export const REACT_APP_MAPBOX_TOKEN = getEnv('REACT_APP_MAPBOX_TOKEN_TO_REPLACE');
export const REACT_APP_DIRECTUS_URL = getEnv('REACT_APP_DIRECTUS_URL_TO_REPLACE');

