import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';
import apiV2 from '../../service/api/ApiV2';
import { Subject, debounceTime } from 'rxjs';
import ToastService from '../../service/ToastService';
import { PlantSearch } from '../../interfaces/PlantSearch';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

type SearchPlantProps = {
  value: number | null;
  onChange: (plantSearch: PlantSearch) => void;
}

export default function AutocompletePlant(props: SearchPlantProps) {

  const [plantsSearch, setPlantsSearch] = useState<PlantSearch[]>([]);
  const [plantSearch, setPlantSearch] = useState<PlantSearch | null>(null);

  useEffect(() => {
    // init plant value
    if (props.value !== null) {
      apiV2.searchPlants(`${props.value}`)
          .then(data => {
              const plant = data.filter(x => x.id === props.value);
              if (plant.length === 0) {
                ToastService.error('Problème lors de la recherche de l\'installation');
                return
              }
              setPlantSearch(plant[0]);
          })
    }
    }, [props.value])

  // searchPlantSubject is used to reduce the number of apiV2 call when searching for plants
  const searchPlantSubject = new Subject<string>();
  searchPlantSubject.pipe(debounceTime(300)).subscribe(
    x => apiV2
      .searchPlants(x)
      .then(setPlantsSearch));

  return (
    <Autocomplete
      id={"plantAutocomplete"}
      // filterOptions => On renvoie toutes les options sans réfléchir, car c'est l'api-v2 côté serveur qui gère
      // Permet de bypass le filtre par défaut du <Autocomplete> qui fait que la recherche doit être LIKE
      // le résultat (nom du site) sauf que dans notre cas il est possible de chercher par serialNumber d'un site
      filterOptions={(options) => options}
      noOptionsText="Aucune installation trouvée"
      disablePortal
      options={plantsSearch}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel= {(option: PlantSearch) => option.name}
      value={plantSearch}
      style={{ width: '100%'}}
      renderInput={(params) =>
        <TextField
          {...params}
          label="Rechercher"
          placeholder="Par nom, numéro de série .."
          helperText="La recherche doit contenir au moins 3 caractères."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{marginLeft: 5}}/>
              </InputAdornment>
            ),
          }}
        />
      }
      onInputChange={(event, newValue) => {
        if (newValue.length >=3){
          searchPlantSubject.next(newValue);
        }
      }}
      onChange={(event, newValue) => {
        setPlantSearch(newValue);
        if (newValue === null) {
          return;
        };
        props.onChange(newValue)
      }}
    />
  );
}
