import { useEffect, useState } from 'react';
import { Card, Tooltip, useTheme } from '@mui/material';
import { SimulationDto } from './dto';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import SimulationStatus from './component/SimulationStatus';
import sizeaApi from '../../../service/api/ApiSizea';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToastService from '../../../service/ToastService';
import SimulationsFilter, { FilterInterface } from './SimulationsFilter';
import DownloadFile from './component/DownloadFile';
import { faBolt, faFaucetDrip } from '@fortawesome/free-solid-svg-icons';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { SimulationRequestInterface } from './simulationRequestDto';
import dayjs from "dayjs"

dayjs.locale("fr-fr")

interface Column {
  id: 'id' | 'name' | 'coordinates' | 'date' | 'user' | 'consumptions' | 'equipments' | 'results' | 'relaunch'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'id', label: 'Identifiant' },
  { id: 'name', label: 'Nom d’installation' },
  { id: 'coordinates', label: 'Coordonnées' },
  { id: 'date', label: 'Date' },
  { id: 'user', label: 'Utilisateur' },
  { id: 'consumptions', label: 'Consommations' },
  { id: 'equipments', label: 'Equipements' },
  { id: 'results', label: 'Résultats' },
  { id: 'relaunch', label: '' },
]

export default function Simulations() {
  const theme = useTheme();

  const [simulations, setSimulations] = useState<SimulationDto[]>([]);
  const [filter, setFilter] = useState<FilterInterface>({
    userId: null,
    search: '',
    startDate: null,
    endDate: null,
  });

  const loadSimulation = (filter: FilterInterface) => {
    sizeaApi.getSimulations(filter).then(data => {
      setSimulations(data);
    });
  }

  useEffect(() => {
    loadSimulation(filter)
  }, [])

  const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
      ToastService.error("Le système de copie n'est pas disponible sur ce navigateur");
      return;
    }

    navigator.clipboard.writeText(text)
      .then(() => {
        ToastService.info('Identifiant copié');
      })
      .catch((err) => {
        ToastService.error('La copie à échoué');
        console.error('Failed to copy text: ', err);
      });
  }

  const filterChange = function (filterValue: FilterInterface) {
    setFilter(filterValue)
    loadSimulation(filterValue)
  }

  const getRelaunchUrl = function (simulation: SimulationDto): string {

    const simulationRequestString = simulation.simulationRequest

    let simulationRequest = null
    if (simulationRequestString) {
      simulationRequest = JSON.parse(simulationRequestString) as SimulationRequestInterface
    }

    let href = `/sizea/form?`
    if (simulationRequest?.plantInput?.name) {
      href += `name=${simulationRequest.plantInput.name}`
    }
    if (simulationRequest?.plantInput?.address) {
      href += `&address=${simulationRequest.plantInput.address}`
    }
    if (simulationRequest?.plantInput?.latitude) {
      href += `&latitude=${simulationRequest.plantInput.latitude}`
    }
    if (simulationRequest?.plantInput?.longitude) {
      href += `&longitude=${simulationRequest.plantInput.longitude}`
    }
    if (simulationRequest?.plantInput?.consumptionFileTypeEnum) {
      href += `&consumptionFileTypeEnum=${simulationRequest.plantInput.consumptionFileTypeEnum}`
    }
    if (simulationRequest?.plantInput?.consumptionFileUuid) {
      href += `&consumptionFileUuid=${simulationRequest.plantInput.consumptionFileUuid}`
    }
    if (simulationRequest?.productionInput?.isRestricted) {
      href += `&isRestricted=1`
    }

    if (simulationRequest?.plantInput?.startDate) {
      href += `&startDate=${simulationRequest.plantInput.startDate}`
    }
    if (simulationRequest?.plantInput?.endDate) {
      href += `&startDate=${simulationRequest.plantInput.endDate}`
    }
    if (simulationRequest?.isCustom) {
      href += `&isCustom=1`
      href += `&template=custom`
    }
    if (simulationRequest?.template && simulationRequest.template !== 'custom') {
      href += `&template=${simulationRequest.template}`
    }
    if (simulationRequest?.customTemplate) {
      href += `&customTemplate=${simulationRequest.customTemplate}`
    }

    const trackerInputs = simulationRequest?.productionInput.trackerInputs
    // le format attendu
    // trackers=[[3,"01111111-9ac7-6509-014b-8b14ec3d7614",22000],[4,"01111111-9ac7-6509-014b-8b14ec3d7613",21500]]
    if (trackerInputs) {
      if (trackerInputs.length > 0) {
        href += '&trackers=[' + trackerInputs.map(item => `[${item.trackerQuantity},"${item.trackerTypeId}",${item.customRatedPower}]`).join(',') + ']';
      }
    }

    const waterPointInputs = simulationRequest?.waterPointInputs
    if (waterPointInputs) {
      if (waterPointInputs.length > 0) {
        href += '&hotWaterRequirements=[' + waterPointInputs.map(item => `[
          "${item.hotWaterTankType}",
          "${item.hotWaterRequirementFileUuid}",
          ${item.volumeCuve},
          ${item.targetTemperature},
          ${item.immersionHeaterQuantity},
          "${item.immersionHeaterTypeId}"
          ]
          `).join(',') + ']';
      }
    }

    const essInputs = simulationRequest?.essInputs
    if (essInputs) {
      if (essInputs.length > 0) {
        href += '&essList=[' + essInputs.map(item => `[
          ${item.rescuedReserve},
          "${item.essTypeId}",
          "${item.controllerTypeId}",
          ${item.nbController},
          "${item.batteryTypeId}",
          ${item.nbBattery}
          ]
          `).join(',') + ']';
      }
    }

    return href
  }

  return (
    <Grid style={{width: '100%', backgroundColor: 'transparent',}}>
      <Card className='search-card'>
        <SimulationsFilter onChange={filterChange} />
      </Card>
      <Card className='custom-card'>
        <TableContainer
          style={{padding: '10px'}}
          component={Paper}
          elevation={0}
        >
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {simulations.map((simulation) => (
                <TableRow
                  key={simulation.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    <span onClick={() => copyToClipboard(simulation.id)}
                      title="Copier l'identifiant"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <ContentCopyIcon
                        sx={{
                          marginRight: 1,
                          color: theme.palette.primary.main,
                        }}
                      />
                      {simulation.id.substring(30, 36)}
                    </span>
                  </TableCell>
                  <TableCell>{simulation.name}</TableCell>
                  <TableCell>
                    <Link color="inherit" target="_blank" href={"https://maps.google.com/?q=" + simulation.latitude + ',' + simulation.longitude}>
                      {simulation.latitude.toFixed(3)} {simulation.longitude.toFixed(3)}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {dayjs(simulation.createdAt).format("DD/MM/YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    <Link color="inherit" href={"mailto:" + simulation.username}>
                      {simulation.username}
                    </Link>
                  </TableCell>
                  <TableCell
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    {simulation.electricalConsumptionFilename &&
                      <DownloadFile
                        title='Fichier de consommation électrique'
                        file={simulation.electricalConsumptionFilename}
                        faIcon={faBolt}
                        type={'source'}
                      />
                    }
                    {simulation.waterPoints.map((waterPoint, idx) => {
                      return <span
                        key={'water-file-' + idx}
                        style={{ marginLeft: '1em' }}
                      >
                        <DownloadFile
                          title='Fichier de consommation en eau'
                          file={`hotwater-${waterPoint.hotWaterRequirementFileUuid}.xlsx`}
                          faIcon={faFaucetDrip}
                          type={'source'}
                        />
                      </span>
                    })}
                  </TableCell>
                  <TableCell>
                    {simulation.equipmentDescriptions && simulation.equipmentDescriptions.map((equipment, idx) => {
                      return <div key={'description-' + idx}>{equipment}</div>
                    })}
                  </TableCell>
                  <TableCell
                    sx={{
                      textWrap: 'nowrap',
                    }}
                  >
                    <SimulationStatus
                      status={simulation.status}
                      errorDescription={simulation.errorDescription}
                      resultExcelFile={simulation.resultExcelFile}
                      resultWordFile={simulation.resultWordFile}
                    />
                  </TableCell>
                  <TableCell>
                    <Link
                      href={getRelaunchUrl(simulation)}
                      rel="noopener noreferrer"
                      target="_blank">
                      <Tooltip title="Relancer une simulation avec ces paramètres">
                        <RestartAltIcon />
                      </Tooltip>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Grid>
  )
}
