import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { CurrentAuthorization } from "./interfaces/CurrentAuthorization";
import { fetchAuthorizations } from "./store/auth/authorization.store";
import { AppDispatch, RootState } from "./store/store";
import { isProduction, REACT_APP_USE_KEYCLOAK_IN_DEV } from "./config";

type PrivateRoutesProps = {
    authField?: keyof CurrentAuthorization
}
const PrivateRoutes = (props: PrivateRoutesProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const authorization = useSelector((state: RootState) => state.authorization.data);
    const [isPageAuthorized, setIsPageAuthorized] = useState<boolean>(false);
    const { keycloak } = useKeycloak();
    const isLoggedIn = isProduction ||  REACT_APP_USE_KEYCLOAK_IN_DEV === 'true' ? keycloak.authenticated : true;
    useEffect(() => {
        if (isLoggedIn  &&  authorization === null) {
            dispatch(fetchAuthorizations());
        }
        if (authorization !== null) {
            if (props.authField) setIsPageAuthorized(authorization[props.authField!])
            else setIsPageAuthorized(true)
        }
    }, [props.authField, authorization, dispatch, isLoggedIn])

    return <>{isLoggedIn && isPageAuthorized ? <Outlet /> : <></>}</>;
};

export default PrivateRoutes;