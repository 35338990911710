import { useEffect, useState } from 'react';
import { forkJoin } from 'rxjs';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import SynchronisedDiv from '../SynchronisedDiv';
import StatusDiv from '../StatusDiv';
import AppCollapse from '../AppCollapse';
import PlantDetails from "./PlantDetails";
import apiV2 from '../../../../../service/api/ApiV2';
import { PlantStatus } from "../../../../../interfaces/PlantStatus";
import { Plant } from '../../../../../interfaces/Plant';
import {AlarmsList} from "../AlarmsList";
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid  from '@mui/material/Grid';
import Button from '@mui/material/Button'
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import { REACT_APP_APP_CLIENT_URL } from '../../../../../config';
import { RootState } from "../../../../../store/store";

type PlantCardProps = {
  plant: Plant;
}

const appClientUrl = REACT_APP_APP_CLIENT_URL;

export default function PlantCard(props: PlantCardProps) {
  const [plantStatus, setPlantStatus] = useState<PlantStatus>();

  const theme = useTheme();
  const navigate = useNavigate();
  const authorization = useSelector((state: RootState) => state.authorization.data);

  const openClientApp = (): void => {
    const newWindow = window.open(`${appClientUrl}/home?plant_id=${props.plant.id}&kc_idp_hint=microsoft`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const openInstallationControlPage = (): void => {
    navigate(`/fleet/control?plant_id=${props.plant.id}`);
  }


  useEffect(() => {
    const status = forkJoin({
      plantStatus: apiV2.plantStatus(props.plant.id),
    });

    status.subscribe({
      next: (results) => {
        setPlantStatus(results.plantStatus);
      },
    })
  }, [props.plant]);
  if (!plantStatus) return <></>;
    return (
        <Card className='custom-card' >
            <Grid container sx={{pb: 0}}>
                <Grid item xs={12}>
                    <CardContent sx={{'&:last-child': {pb: 1}}}>
                      <Typography component="div" className='scada-card-title'>
                          {props.plant.name}
                          <span style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.secondaryFont }}> {props.plant.serialNumber}</span>
                      </Typography>
                      <SynchronisedDiv isSynchronised={plantStatus.isSynchronised}/>
                      {plantStatus.isSynchronised && <StatusDiv statusType={plantStatus.statusType}/>}
                      <Grid item xs={12} sx={{ mt: "10px" }} style={{textAlign: 'center'}}>
                        <Button
                          sx={{
                              marginRight: 2,
                              marginLeft: 0,
                              width: 200,
                              "&.MuiButton-root": {
                                  color: (theme.palette.mode === 'dark') ? 'white' : "#00000099",
                                  borderColor: (theme.palette.mode === 'dark') ? 'white' : "#00000099"
                                  }
                          }}
                          variant="outlined"
                          onClick={() => openClientApp()}
                          startIcon={<PhonelinkIcon />}>
                              Espace client
                        </Button>
                        {
                          (authorization?.authAppInstallationControl)  &&
                        <Button
                          sx={{
                              marginRight: 2,
                              marginLeft: 0,
                              marginTop: 1,
                              width: 200,
                          }}
                          color="primary"
                          variant="outlined"
                          onClick={() => openInstallationControlPage()}
                          startIcon={<ChecklistOutlinedIcon />}>
                              Contrôle
                        </Button>
                      }
                      </Grid>
                    </CardContent>
                </Grid>
                <Grid item xs={12}>
                    <CardContent sx={{'&:last-child': {pt: 0, pb: 1}}}>
                        <AppCollapse title="Détails">
                            <PlantDetails plant={props.plant}/>
                            {plantStatus.alarms && <AlarmsList alarms={plantStatus.alarms} />}
                        </AppCollapse>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}