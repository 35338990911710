import { Grid } from "@mui/material";
import Filters from "./filters/Filters";
import ChartArea from "./charts/ChartArea";

export default function PlantsDataView() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Filters />
            </Grid>
            <Grid item xs={12}>
                <ChartArea />
            </Grid>
        </Grid>
    )
}