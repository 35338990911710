import './App.css';
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import GA from 'react-ga4';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import PrivateRoutes from './PrivateRoutes';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ConfigProvider, theme as themeAntd} from "antd";
import getTheme from './ui/theme';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { defaults } from 'chart.js';
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ArcElement,
  Filler
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { RootState } from './store/store';
import { isProduction, REACT_APP_USE_KEYCLOAK_IN_DEV } from './config';

require('dayjs/locale/fr');
dayjs.locale('fr');
dayjs.extend(tz);
dayjs.extend(utc);

defaults.font.family = 'Montserrat'

// register globally these plugins -> they can be used anywhere in the app
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  ArcElement,
  Filler,
);

if(!isProduction && REACT_APP_USE_KEYCLOAK_IN_DEV !== 'true') {
    keycloak.authenticated = true;
    // token de test de l'API Sizea (utilisateur1@okwind.fr)
    keycloak.token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIzRHJuNTh3UDE5LUZhVmVIdGlvNDUya3dJcXZoa3hLY0tuSVpyU2QzOXpZIn0.eyJleHAiOjE2NTQ2OTAwMjYsImlhdCI6MTY1NDY4OTk2NiwianRpIjoiMzYzZTY2OTUtOGUxZC00OGNlLTg3OGMtODQyYjA0MDEzYzU3IiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4OTk5L3JlYWxtcy9tYXN0ZXIiLCJhdWQiOlsicG9ydGFpbC1yZWFsbSIsImFjY291bnQiXSwic3ViIjoiMGY1NDc1YmYtMjc3ZC00NWQwLWE4YTUtY2I4YjU3NWMxYmVmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYXBpLXYyLWNsaSIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZGVmYXVsdC1yb2xlcy1tYXN0ZXIiLCJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsicG9ydGFpbC1yZWFsbSI6eyJyb2xlcyI6WyJtYW5hZ2UtdXNlcnMiLCJ2aWV3LXVzZXJzIiwicXVlcnktZ3JvdXBzIiwicXVlcnktdXNlcnMiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsImNsaWVudEhvc3QiOiIxNzIuMjQuMC4xIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJjbGllbnRJZCI6ImFwaS12Mi1jbGkiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzZXJ2aWNlLWFjY291bnQtYXBpLXYyLWNsaSIsImNsaWVudEFkZHJlc3MiOiIxNzIuMjQuMC4xIn0.MApUUdCMRlOJgmdKMJaVN1XH1wkCvyrzz_ovW4SQxsZXlRXNoixQE_UKJz0jzGIHjIRibYcMu3GHZKMq7fXbNgWC47YklaVvNNl8OsCsfVaFYj8bpRNbFr5rFht7jmuBhg7rMyOPwGu3T6GAQrj8eXqUgAdW32--pxLcIaSVaGZ6A1s8Aeq-E32TCKjLod7E77kjIgvKsJ0daoievft6BoahMyEPsFfKitgILQa_PDPqbHLDkDCr3IxAhxPMeKedk3xRDA1olYXJq7-AJq0y57Jx1SmDyF62wBWQgmNeub_2IJgvZdowI2Y2DHCm6IkUZ1SNAfKDc9ucInI2E-fXCw';
}

function App() {
  const { defaultAlgorithm, darkAlgorithm } = themeAntd;
  const [mode, setMode] = useState('light');
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  // Google Analytics
  const location = useLocation();
  useEffect(() => {
    GA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });
  }, [location]);

  useMemo(() => {
    if (darkMode) {
      setMode('dark');
    } else {
      setMode('light');
    }
  }, [darkMode]);

  const theme = useMemo(() => getTheme(mode), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        theme={{
          algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        }}>
        <SnackbarProvider maxSnack={3}>
          <ReactKeycloakProvider authClient={keycloak} initOptions={{
            onLoad: 'login-required'
          }}>
            <PrivateRoutes />
          </ReactKeycloakProvider>
        </SnackbarProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
