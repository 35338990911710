import { ImmersionHeater } from "../../../../../interfaces/ImmersionHeater";
import ImmersionHeaterCard from "./ImmersionHeaterCard";

type ImmersionHeaterCardsProps = {
    immersionHeaters: {isSelected: boolean, immersionHeater: ImmersionHeater}[];
}

export function ImmersionHeaterCards(props: ImmersionHeaterCardsProps) {
    return (
        <>
            {
                props.immersionHeaters.map(immersionHeater => {
                    return (
                        <ImmersionHeaterCard
                            key={immersionHeater.immersionHeater.id}
                            immersionHeater={immersionHeater.immersionHeater}
                            isSelected={immersionHeater.isSelected}
                        />
                    )
                })
            }
        </>
    )
}