import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchAgencies } from "../../store/form/agencies.store";
import { AppDispatch, RootState } from "../../store/store";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { UseFormRegister } from 'react-hook-form';
import { CreatePlant, UpdatePlant } from '../../interfaces/plant/Plant';
import { SxProps } from '@mui/material';

interface OptionInterface {
    required: boolean
}

interface PropsInterface {
    name: 'agencyId'
    label: string
    preloadValue: number | null | undefined
    register: UseFormRegister<UpdatePlant | CreatePlant>
    options: OptionInterface
    error: boolean
    helpertext: string | null
    sx: SxProps
}

export default function SelectAgency(props: PropsInterface) {
    const dispatch = useDispatch<AppDispatch>();
    const agenciesList = useSelector((state: RootState) => state.agencies.data);

    const {register, options, name, ...selectProps} = props;

    useEffect(() => {
        if(agenciesList.length === 0) dispatch(fetchAgencies())
    }, []);

    const getMenuItemAgency = () => {
        const menu: React.JSX.Element[] = []
        Object.values(agenciesList).forEach((value, index) => {
            menu.push(<MenuItem data-cy={`cypressSelectAgencyOption${index}`} key={index} value={value.id}>{value.name}</MenuItem>)
        })

        return menu
    }

    return (
        <>
            <InputLabel error={props.error} id="selectAgancyLabel" required={props.options.required}>{props.label}</InputLabel>
            <Select
                {...selectProps}
                {...register(name, options)}
                labelId="selectAgancyLabel"
                data-cy="cypressSelectAgency"
                id="selectAgency"
                value={
                    (props.preloadValue === undefined ||
                    props.preloadValue === null) ? '' : props.preloadValue}
                color='primary'
                required={props.options.required}
            >
                { getMenuItemAgency() }
            </Select>
            <FormHelperText error>{props.helpertext}</FormHelperText>
        </>
    );
}
