import { Line } from 'react-chartjs-2';
import { mergeObjectsRecursive } from '../../../../utils/utils';
import { Plant } from '../../../../interfaces/Plant';
import { useEffect, useState, useRef } from 'react';
import { generateDates, formatXLabels } from '../DateService';
import dayjs, { Dayjs } from 'dayjs';
import apiV2 from '../../../../service/api/ApiV2';
import { fillMissingDate } from '../ParseRawData';
import { useTheme } from '@mui/material';
import type { ChartData, ChartOptions, ChartDataset } from 'chart.js';
import { Weather } from '../../../../interfaces/Weather';
import { Tracker } from '../../../../interfaces/Tracker';
import { TrackerWind } from '../../../../interfaces/TrackerWind';
import { forkJoin } from 'rxjs';
import { CrosshairPlugin } from '../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair';
import { catchError, of, from } from 'rxjs';
import { ResizableBox } from 'react-resizable'
import "react-resizable/css/styles.css";

import GraphResizeService  from '../../../../service/GraphResizeService'

type WindChartProps = {
    plant: Plant;
    trackers: Tracker[];
    startDate: Dayjs;
    endDate: Dayjs;
    options: ChartOptions<'line'>;
}

const flatLabel = 'Seuil de mise à plat';

const defaultOptions: ChartOptions<'line'> = {
    responsive: true,
        maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            text: 'Vent (m/s)',
        },
        tooltip: {
            filter: function (tooltipItem) {
                return tooltipItem.dataset.label !== flatLabel;
            }
        }
    },
};

export function WindChart(props: WindChartProps) {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement|null>(null)
    const options = mergeObjectsRecursive(defaultOptions, props.options)
    const [chartData, setChartData] = useState<ChartData<'line'>>({labels:[], datasets: []});
    const WindThreshold = 11; // unit: m/s  (11m/s=40km/h) wind that trigger the flattening of tracker
    const [width, setWidth] = useState(0)

    useEffect(() => {
        GraphResizeService.checkResizeWidth(ref, width, setWidth)
      },
    [ref, width, setWidth])

    useEffect(() => {
        const expectedDates = generateDates(props.startDate, props.endDate);

        async function updatePlantDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const now = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');
            datasets.push(
                {
                    label: flatLabel,
                    data: expectedDates.map(() => WindThreshold),
                    borderColor: theme.palette.windThresoldCurve,
                    backgroundColor: theme.palette.windThresoldCurve,
                    borderWidth: 2,
                });

            const weather = await apiV2.weather(props.startDate, props.endDate, props.plant.id)

            fillMissingDate(weather, expectedDates);
            datasets.push(
                {
                    label: 'Vent du site',
                    data: weather.map((x: Weather) => x.date <= now ? x.calibratedWindGusts : null),
                    spanGaps: true,
                    borderColor: theme.palette.plantWindCurve,
                    backgroundColor: theme.palette.plantWindCurve,
                    borderWidth: 2,
                    tension: 0.4,
                });
        }

        async function updateTrackersDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const nbColors = theme.palette.trackerWindCurves.length
            const tasks = []
            for (const tracker of props.trackers) {
                tasks.push(apiV2.trackerWindSpeed(props.startDate, props.endDate, tracker.id))
            }
            await Promise.all(tasks).then((results) => {
                for (let i = 0; i < results.length; i++) {
                    fillMissingDate(results[i], expectedDates);
                    datasets.push(
                        {
                            label: `Vent du tracker ${props.trackers[i].nTrk}`,
                            data: results[i].map((x: TrackerWind) => x.windSpeed),
                            spanGaps: true,
                            borderColor: theme.palette.trackerWindCurves[i % nbColors],
                            backgroundColor: theme.palette.trackerWindCurves[i % nbColors],
                            borderWidth: 2,
                            tension: 0.4,
                        });
                }
            })
        }
        const datasets: ChartDataset<'line'>[] = [];
        const waitForDatasets = forkJoin({
            plantDatasets: from(updatePlantDatasets(datasets)).pipe(catchError(() => of(null))),
            trackersDatasets: from(updateTrackersDatasets(datasets)).pipe(catchError(() => of(null))),
        })
        waitForDatasets.subscribe({
            next: () => {
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: datasets
                })
            },
            error: () => {
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: []
                })
            }
        })
    }, [props, theme])

    const chartHeight = 180

    return (
        <div ref={ref} className='scada-card-zoom' >
            <ResizableBox  transformScale={0.75} className="box" width={width} height={chartHeight} resizeHandles={['s']} minConstraints={[100, chartHeight]} maxConstraints={[width, chartHeight*4]}>
            {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
                <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: '15px'}}/>
            </ResizableBox>
        </div>
    )
}