import { useState, useRef, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { REACT_APP_GOOGLE_MAP_KEY } from '../../config';

const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAP_KEY;
const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/'

const autocompleteService = { current: null };
const geocodeService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface AutocompletePlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

interface PlaceDetailType {
  geometry: any;
}

export default function GoogleMaps(props: any) {
  const [autocompletePlaceType, setAutocompletePlaceType] = useState<AutocompletePlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [optionsPlaceType, setOptionsPlaceType] = useState<readonly AutocompletePlaceType[]>([]);
  const loaded = useRef(false);

  // si utilisation de loadScript, être sûr d'avoir chargé la librairie gogole "places"
  const {register, setValue, trigger, options, address, placeId, latitude, longitude, latLngPrecision, doNotLoadGoogleMapScript, ...selectProps} = props;

  function doLoadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
      return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
    loaded.current = true;
  }

  if (!doNotLoadGoogleMapScript && typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      doLoadScript(
        GOOGLE_MAPS_API_URL + 'js?key='+ GOOGLE_MAPS_API_KEY +'&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }
  }

  const fetchAddress = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly AutocompletePlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        300,
      ),
    [],
  );

  const fetchPlaceDetail = (
    request: { placeId: string },
    callback: (results: readonly PlaceDetailType[]) => void
    ) => {
    geocodeService.current = new (
      window as any
    ).google.maps.Geocoder();
    (geocodeService.current as any).geocode(
      request,
      callback,
    );
  }

  const onClear = (reason: any) =>{
    if (reason === 'clear') {
      setValue(address, "")
      placeId && setValue(placeId, "")
      latitude && setValue(latitude, null)
      longitude && setValue(longitude, null)
      trigger(address)
      return
    }
  }

  const applyPrecision = (value: number) => {
    return latLngPrecision === undefined ? value : Number(value.toFixed(latLngPrecision));
  }

  useEffect(() => {
    if(props.preloadvalue && autocompletePlaceType === null){
      const preloadPlaceType = {} as AutocompletePlaceType;
      preloadPlaceType.description = props.preloadvalue
      setAutocompletePlaceType(preloadPlaceType);
    }
  }, [props.preloadvalue, autocompletePlaceType])

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google?.maps?.places) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptionsPlaceType(autocompletePlaceType ? [autocompletePlaceType] : []);
      return undefined;
    }

    fetchAddress({ input: inputValue }, async (results?: readonly AutocompletePlaceType[]) => {
      if (active) {
        let newOptions: readonly AutocompletePlaceType[] = [];
        if (autocompletePlaceType && autocompletePlaceType.place_id) {
          setValue(address, autocompletePlaceType.description)
          fetchPlaceDetail({ placeId: autocompletePlaceType.place_id }, async (results: readonly PlaceDetailType[]) => {
            if(results.length > 0){
              placeId && setValue(placeId, autocompletePlaceType.place_id)
              latitude && setValue(latitude, applyPrecision(results[0].geometry.location.lat()))
              longitude && setValue(longitude, applyPrecision(results[0].geometry.location.lng()))
            }
          })
          newOptions = [autocompletePlaceType];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptionsPlaceType(newOptions);
      }

    });
    trigger(address)

    return () => {
      active = false;
    };

  }, [autocompletePlaceType, inputValue, fetch]);

  return (
    <Autocomplete
      id="autocompleteAddress"
      {...register(address, options)}
      getOptionLabel={(option: AutocompletePlaceType) => option.description}
      isOptionEqualToValue={(option:  AutocompletePlaceType, value) => {
        return (option.description === value.description);
      }}
      filterOptions={(x) => x}
      options={optionsPlaceType}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={autocompletePlaceType}
      noOptionsText="Aucune adresse trouvée"
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event: any, newValue: AutocompletePlaceType | null, reason) => {
        setOptionsPlaceType(newValue ? [newValue, ...optionsPlaceType] : optionsPlaceType);
        setAutocompletePlaceType(newValue);
        setValue(address, newValue?.description)
        onClear(reason);
      }}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            {...selectProps}
            required={options.required}
            label={props.label}
            InputLabelProps={{shrink: true}}
            placeholder="Rechercher l'adresse par ville, rue.."
            helperText={(props.error && options.required) ? "L'adresse est obligatoire" : null}
            fullWidth />
        </div>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props} id='test'>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}