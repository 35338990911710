import apiSizea from '../../../../service/api/ApiSizea';
import { useEffect, useState } from 'react';
import {BatteryType} from "../../../../interfaces/ess/BatteryType";
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { SelectType } from './SelectType';
import { SxProps, Theme } from '@mui/material';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    error: boolean | undefined
    sx?: SxProps<Theme>
}

export function SelectBatteryType<T extends FieldValues>(props: Props<T>) {
    const [batteryTypes, setBatteryTypes] = useState<BatteryType[]>([]);

    useEffect(() => {
        apiSizea.batteryTypes().then(data => {
            setBatteryTypes(data);
        });
    }, [])

    if (batteryTypes.length === 0) {
        return <></>
    }

    return <SelectType
        types={batteryTypes}
        sx={props.sx}
        error={props.error}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
    />
}
