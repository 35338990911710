import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import Card from '@mui/material/Card'
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from '@mui/material/Button'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PhonelinkIcon from '@mui/icons-material/Phonelink';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PersonIcon from '@mui/icons-material/Person';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import {formatDate, nbDayBetweenToday} from "../../../../service/FormatDate";
import { PlantSearch } from '../../../../interfaces/PlantSearch';
import { REACT_APP_APP_CLIENT_URL } from '../../../../config';

interface propsInterface {
    plantSearch: PlantSearch | null;
}

export default function PlantCard(props: propsInterface) {
    const appClientUrl = REACT_APP_APP_CLIENT_URL;
    const theme = useTheme();
    const navigate = useNavigate();

    const displaySecondaryClient = () => {
        const clientLength = (props?.plantSearch) ? props.plantSearch.clients.length : 0;
        if(clientLength <= 1) return <span></span>
        if(clientLength === 2) return <span>1 client secondaire</span>
        if(clientLength > 2) return <span>{(clientLength - 1)} clients secondaires</span>
    }

    const openClientApp = (): void => {
        const newWindow = window.open(`${appClientUrl}/home?plant_id=${props.plantSearch?.id}&kc_idp_hint=microsoft`, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const openScadaApp = (): void => {
        const today = new Date().toISOString().split('T')[0]
        navigate('/scada/plant?start_date=' + today + '&end_date=' + today + '&plant_id=' + props.plantSearch?.id);
    }

    const getPlantSerial = (): string => {
        return props?.plantSearch?.serialNumber ? '(' + props?.plantSearch?.serialNumber + ')' : '';
    }

    return (
        <Card className='custom-card' style={{ paddingBottom: 10, paddingRight: 5, paddingLeft: 5}} data-testid="PlantCard">

            <Grid container justifyContent="space-between" sx={{ backgroundColor: 'transparent'}}>
                <Grid item>
                    {
                        (props.plantSearch)
                        ?
                        <div>
                            <List dense>
                                <ListItem className='control-plant-list'>
                                    <ListItemText
                                        data-testid="plantInfos"
                                        primary={
                                            <React.Fragment>
                                                <h2 style={{marginBottom: 0}}>{props?.plantSearch.name}&nbsp;<span style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.secondaryFont }}>{getPlantSerial()}</span></h2>
                                            </React.Fragment>
                                        }
                                        secondary={props.plantSearch.address}
                                    />
                                </ListItem>
                                <ListItem className='control-plant-list'>
                                    <ListItemIcon style={{marginRight: '-15px'}}>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        data-testid="clientInfos"
                                        primary={props?.plantSearch.clients[0].firstName + ' ' + props?.plantSearch.clients[0].lastName.toUpperCase() + ' - ' + props?.plantSearch.clients[0].email}
                                        secondary={
                                            <React.Fragment>
                                            {displaySecondaryClient()}
                                            </React.Fragment>
                                        }/>
                                </ListItem>
                                <ListItem className='control-plant-list'>
                                    <ListItemIcon style={{marginRight: '-15px'}}>
                                        <PendingActionsIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        data-testid="agencyInfos"
                                        primary={'Agence: ' + props.plantSearch.agency}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                                >
                                                    { 'Mise en route: ' + formatDate(props?.plantSearch?.operationDate) + ' (il y a ' + nbDayBetweenToday(props?.plantSearch?.operationDate) + ' jours)'}
                                                </Typography>
                                                {
                                                    (parseInt(nbDayBetweenToday(props?.plantSearch?.operationDate)) < 5)
                                                    ?
                                                    <Tooltip
                                                        title="Attention, la mise en route est récente ce qui peut occasionner un manque de recul pour établir d'un bon fonctionnement"
                                                    >
                                                        <WarningAmberIcon  color='warning' style={{marginBottom: '-5px', marginLeft: '5px'}} />
                                                    </Tooltip>
                                                    :
                                                    <></>
                                                }
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </div>
                        :
                        <></>
                    }
                </Grid>
                <Grid style={{ paddingTop: 20, paddingBottom: 0}} item>
                    <Button
                        data-testid="openScadaButton"
                        color="primary"
                        sx={{marginRight: 2, marginBottom: 2, marginLeft: 2}}
                        variant="outlined"
                        onClick={() => openScadaApp()}
                        startIcon={<QueryStatsIcon />}>
                            Voir dans scada
                    </Button>
                    <Button
                        data-testid="openClientAppButton"
                        sx={{
                            marginRight: 2,
                            marginBottom: 2,
                            marginLeft: 2,
                            "&.MuiButton-root": {
                                color: (theme.palette.mode === 'dark') ? 'white' : "#00000099",
                                borderColor: (theme.palette.mode === 'dark') ? 'white' : "#00000099"
                                }
                        }}
                        variant="outlined"
                        onClick={() => openClientApp()}
                        startIcon={<PhonelinkIcon />}>
                            Espace client
                    </Button>
                </Grid>
            </Grid>
        </Card>
    );
}