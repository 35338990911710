import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";

interface propsInterface {
    title: string;
}

export default function TemporarilyUnavailableCard(props: propsInterface) {
    return (
        <Card className='custom-card' style={{ marginTop: 20, paddingBottom: 10, paddingRight: 5, paddingLeft: 5 }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {props.title}
                </Typography>
                <Typography style={{maxWidth: 400, margin: 'auto'}}>
                    Aucun contrôle disponible pour cet équipement
                </Typography>
            </CardContent>
        </Card>
    );
}