import * as React from 'react';
import {useEffect, useState} from 'react';
import {FR, GB} from 'country-flag-icons/react/3x2';
import {FR as FRlittle, GB as GBlittle} from 'country-flag-icons/react/1x1';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import {Alert, Chip, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

import SearchTextField from '../../../component/form/SearchTextField';
import apiDirectus from '../../../service/api/ApiDirectus';
import {FileSearch} from '../../../interfaces/documentation/FileSearch';
import DirectusService from '../../../service/DirectusService';
import DownloadButton from '../../../component/DownloadButton';
import DocumentTypeView from './DocumentTypeView';
import DateView from './DateView';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface DirectusFieldOption {
  text: string;
  value: string;
}

export default function ListsFileSearchView() {
  const [rows, setRows] = useState<Array<FileSearch>>([]);
  const [documentTypes, setDocumentTypes] = useState<Array<DirectusFieldOption>>([]);
  const [models, setModels] = useState<Array<DirectusFieldOption>>([]);
  const [languages, setLanguages] = useState<Array<DirectusFieldOption>>([]);
  const [searchDocumentation, setSearchDocumentation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string[]>([]);
  const [selectedModel, setSelectedModel] = useState<string[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onChange = (searchValue: string) => {
    setRows([]);
    setLoading(true);

    const currentLanguages = selectedLanguage.length === 0 ? null : selectedLanguage;
    const currentSearchValue = searchValue || null;
    const currentModels = selectedModel.length === 0 ? null : selectedModel;
    const currentDocumentTypes = selectedDocumentType.length === 0 ? null : selectedDocumentType;

    apiDirectus.getFiles(currentSearchValue, currentDocumentTypes, currentModels, currentLanguages).then(async (data: FileSearch[]) => {
      if (data.length > 0) {
        setRows(data);
      }
      setLoading(false);
    });
    setSearchDocumentation(searchValue);
  };

  const handleChangeMultiple = (data: DirectusFieldOption[], callback: React.Dispatch<React.SetStateAction<string[]>>) => {
    callback(data.map(a => a.value));
  };

  const loadData = (typeField: string, callback: React.Dispatch<React.SetStateAction<DirectusFieldOption[]>>) => {
    apiDirectus.getCustomField(typeField).then(response => {
      callback(response.meta.options.choices.sort((choice1, choice2) => {
        return choice1.text.localeCompare(choice2.text);
      }));
    });
  };

  useEffect(() => {
    loadData('Type_de_document', setDocumentTypes);
    loadData('Modele', setModels);
    loadData('Langage', setLanguages);
  }, []);

  useEffect(() => {
    if (models.length > 0 && documentTypes.length > 0) onChange(searchDocumentation);
  }, [selectedLanguage, selectedDocumentType, selectedModel, models, documentTypes]);

  const gridColumns: GridColDef<FileSearch>[] = [
    {
      field: 'title',
      headerName: 'Titre',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <List>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body2" color="text.primary">
                  {params.value}
                </Typography>
              }
            />
          </ListItem>
        </List>
      ),
    },
    {
      field: 'Type_de_document',
      headerName: 'Type de document',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <DocumentTypeView documentTypes={documentTypes} typeDeDocument={params.value}/>
      ),
    },
    {
      field: 'Langage',
      headerName: 'Langue',
      maxWidth: 100,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
          {params.value === 'fr' && <FR title="Français" style={{borderRadius: '8px', width: '40px'}}/>}
          {params.value === 'en' && <GB title="English" style={{borderRadius: '8px', width: '40px'}}/>}
        </Box>
      ),
    },
    {
      field: 'Version',
      headerName: 'Version',
      maxWidth: 100,
      flex: 1,
    },
    {
      field: 'Publication',
      headerName: 'Date',
      maxWidth: 150,
      flex: 1,
      renderCell: (params) => <DateView date={params.value}/>,
    },
    {
      field: 'filesize',
      headerName: 'Poids',
      maxWidth: 100,
      flex: 1,
      sortable: false,
      valueFormatter: (params) => `${(params.value / 1000000).toFixed(1)} Mo`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <DownloadButton
              icon={true}
              url={DirectusService.getUrlDownload(params.row)}
              filename={params.row.filename_download}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  return (
    <Grid container sx={{backgroundColor: 'transparent'}}>
      <Card className='search-card'>
        <Grid container sx={{backgroundColor: 'transparent'}}>
          <Grid item xs={12} md={6} lg={3} style={{paddingRight: isMobile ? '0px' : '20px'}}>
            <SearchTextField
              value={searchDocumentation}
              width={'100%'}
              notifyOnClear={true}
              placeholder="Par nom, modèle, description..."
              onChange={onChange}
              helper="La recherche doit contenir au moins 3 caractères."
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} style={{paddingRight: isMobile ? '0px' : '20px'}}>
            <FormControl fullWidth style={{marginTop: isMobile ? '0px' : '15px'}}>
              <Autocomplete
                limitTags={2}
                multiple
                id="checkboxes-type-doc"
                options={documentTypes}
                disableCloseOnSelect
                onChange={(event: any, newValue: DirectusFieldOption[]) => {
                  handleChangeMultiple(newValue, setSelectedDocumentType);
                }}
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 2;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{maxWidth: numTags > limitTags - 1 ? '100px' : '100%'}}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.text}
                    </li>
                  );
                }}
                style={{width: '100%', flexWrap: 'nowrap'}}
                renderInput={(params) => (
                  <TextField {...params} label="Type de document"/>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3} style={{paddingRight: isMobile ? '0px' : '20px'}}>
            <FormControl fullWidth style={{marginTop: isMobile ? '0px' : '15px'}}>
              <Autocomplete
                limitTags={2}
                multiple
                id="checkboxes-model"
                options={models}
                disableCloseOnSelect
                onChange={(event: any, newValue: DirectusFieldOption[]) => {
                  handleChangeMultiple(newValue, setSelectedModel);
                }}
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 2;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{maxWidth: numTags > limitTags - 1 ? '100px' : '100%'}}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.text}
                    </li>
                  );
                }}
                style={{width: '100%', flexWrap: 'nowrap'}}
                renderInput={(params) => (
                  <TextField {...params} label="Modèle"/>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={2} style={{paddingRight: isMobile ? '0px' : '20px'}}>
            <FormControl fullWidth style={{marginTop: isMobile ? '0px' : '15px'}}>
              <Autocomplete
                limitTags={2}
                multiple
                id="checkboxes-languages"
                options={languages}
                disableCloseOnSelect
                onChange={(event: any, newValue: DirectusFieldOption[]) => {
                  handleChangeMultiple(newValue, setSelectedLanguage);
                }}
                getOptionLabel={(option) => option.text}
                renderTags={(value, getTagProps) => {
                  const numTags = value.length;
                  const limitTags = 1;
                  return (
                    <Box>
                      {value.slice(0, limitTags).map((option, index) => (
                        <Chip
                          style={{maxWidth: numTags > limitTags - 1 ? '100px' : '100%'}}
                          {...getTagProps({index})}
                          key={index}
                          label={option.text}
                        />
                      ))}
                      {numTags > limitTags && ` +${numTags - limitTags}`}
                    </Box>
                  );
                }}
                renderOption={(props, option, {selected}) => {
                  const {key, ...optionProps} = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                      />
                      {option.value === 'fr' && <FRlittle title="Français" style={{
                        marginRight: '10px',
                        borderRadius: '50px',
                        width: '20px'
                      }}/>}
                      {option.value === 'en' && <GBlittle title="English" style={{
                        marginRight: '10px',
                        borderRadius: '50px',
                        width: '20px'
                      }}/>}
                      {option.text}
                    </li>
                  );
                }}
                style={{width: '100%', flexWrap: 'nowrap'}}
                renderInput={(params) => (
                  <TextField {...params} label="Langue"/>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      <Card className='custom-card'>
        <Grid container sx={{backgroundColor: 'transparent', pl: 0, pr: 0}}>
          <Grid item xs={12} sx={{p: 2}}>
            <Paper sx={{width: '100%', overflow: 'hidden', boxShadow: 'none'}}>
              <div style={{width: '100%', maxWidth: '100%'}}>
                {(!loading && rows.length === 0) &&
                    <Alert severity="warning">
                        Désolé, aucune donnée ne correspond à votre recherche. Essayez d'ajuster vos critères de
                        recherche.
                    </Alert>
                }

                {(!loading && rows.length > 0) &&
                    <DataGrid
                        rows={rows}
                        columns={gridColumns}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          }
                        }}
                        pageSizeOptions={[10,25,50]}
                        loading={loading}
                        getRowId={(row) => row.id}
                        disableRowSelectionOnClick={true}
                        disableColumnSelector={true}
                        disableDensitySelector={true}
                        disableColumnFilter={true}
                        disableColumnMenu={true}
                    />
                }
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}