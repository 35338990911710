import apiSizea from '../../../../service/api/ApiSizea';
import { useEffect, useState } from 'react';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { SelectType } from './SelectType';
import { EssType } from '../../../../interfaces/ess/EssType';
import { SxProps, Theme } from '@mui/material';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
    error: boolean | undefined
    sx?: SxProps<Theme>
}

export function SelectEssType<T extends FieldValues>(props: Props<T>) {
    const [essTypes, setEssTypes] = useState<EssType[]>([]);
    useEffect(() => {
        apiSizea.essTypes().then(data => {
            setEssTypes(data)
        });
    }, [])

    if (essTypes.length === 0) {
        return <></>
    }

    return <SelectType
        types={essTypes}
        sx={props.sx}
        error={props.error}
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
    />
}
