import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://okwind.fr/">
                Okwind
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    return (
        <Box component="footer" style={{ marginTop: '-20px'}} sx={{ py: 2 }}>
            <Container maxWidth="lg">
                <Copyright />
            </Container>
        </Box>
    );
}