import React from 'react';
import dayjs from "dayjs";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import {Tooltip} from "@mui/material";

interface DateViewProps {
  date: string;
}

const DateView: React.FC<DateViewProps> = ({date}) => {
  if (date === null) {
    return <></>
  }

  const dateFormated = dayjs(date);
  const todayLessOneMonth = dayjs().subtract(1, 'month')

  if (dateFormated.isAfter(todayLessOneMonth)) {
    return (
      <>
        <span>{dateFormated.format('DD/MM/YYYY')}</span>
        <Tooltip title="Nouveau" arrow placement='top'>
          <NewReleasesIcon sx={{color: '#35BA07E5', marginLeft: '5px'}}/>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {dateFormated.format('DD/MM/YYYY')}
    </>
  );
};

export default DateView;