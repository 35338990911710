import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { StatusCodes } from 'http-status-codes';

import { useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

import { Grid } from '@mui/material'
import { Card } from '@mui/material'
import { Button } from '@mui/material'
import { IconButton } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import SearchTextField from '../../../component/form/SearchTextField';
import CustomNoRowsOverlay from '../../../component/CustomNoRowsOverlay';

import Box from '@mui/material/Box';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import ToastService from '../../../service/ToastService';

import DialogClient from './DialogClient';
import ConfirmDialogService from '../../../service/ConfirmDialogService';

import { ClientPlant } from '../../../interfaces/client/ClientPlant';
import { ClientSearch } from '../../../interfaces/client/ClientSearch';

import apiV2 from '../../../service/api/ApiV2';
import { REACT_APP_APP_CLIENT_URL } from '../../../config';

export default function ListsClientSearchView() {
    const theme = useTheme();
    const appClientUrl = REACT_APP_APP_CLIENT_URL;
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const navigate = useNavigate();

    interface Column {
        id: 'client' | 'email' | 'phone' | 'actions';
        label: string;
        description: string,
        minWidth?: number;
        align?: 'center';
        format?: (value: number) => string;
    }

    const [rows, setRow] = useState<Array<ClientSearch>>([]);
    const [searchClient, setSearchClient] = useState<string>("");
    const [clientSearchUpdated, setClientSearchUpdated] = useState<ClientSearch | null>();
    const [titleDialog, setTitleDialog] = useState<string>("");
    const [actionLabelDialog, setActionLabelDialog] = useState<string>("Créer");
    const [contentTextDialog, setContentTextDialog] = useState<string>("");
    const [contentTextErrorDialog, setContentTextErrorDialog] = useState<string>("");
    const [clientPlant, setClientPlant] = useState<Array<ClientPlant>>([]);
    const [open, setOpen] = useState(false);
    const [openDeleteError, setOpenDeleteError] = useState(false);

    const onChange = (searchedVal: string) => {
        apiV2.searchClients(searchedVal).then(data => {
            if(data.length > 0){
                setRow(data);
            }else{
                ToastService.warning("Aucun client trouvé pour votre recherche");
            }
        })
        setSearchClient(searchedVal)
    };

    const handleCloseDialogClient = () => {
        setOpen(false);
        setClientSearchUpdated(null);
    };

    const handleCloseDialogDeleteError = () => {
        setOpenDeleteError(false);
    };

    const handleAddNewClient = () => {
        setTitleDialog('Créer un nouveau client')
        setContentTextDialog('Déclarer un nouveau compte client. Cette opération va créer un utilisateur qui pourra accèder à l\'espace client pour qu\'il consulte les données des installations qui lui seront associées.')
        setActionLabelDialog('Créer')
        setOpen(true);
    };

    const handleUpdateClient = (client: ClientSearch) => {
        setTitleDialog('Modifier un client')
        setContentTextDialog('Modifier le compte client ' + client.firstName + ' ' + client.lastName + ' ou completez ses données manquantes.')
        setActionLabelDialog('Modifier')
        setOpen(true);
        setClientSearchUpdated(client);
    };

    const handleUpdateRowClient = (client: ClientSearch) => {
        setRow(rows.map((row) => {
            if (row.id === client.id) return client
            return row;
        }));
    };

    const handleRemoveClient = (client: ClientSearch) => {
        ConfirmDialogService.warning(
          'md',
          'Supprimer le client',
          'Etes-vous sur de vouloir supprimer le client ' + client.firstName + ' ' + client.lastName + ' ?',
          'Cette action est irréversible.',
          () => {
            apiV2.deleteClient(client.id).then(response => {
                if(response.ok){
                    ToastService.success('Le client ' + client.firstName + ' ' + client.lastName + ' a été supprimé')
                    setRow(rows.filter((row) => row.id !== client.id));
                }
                else if(response.status === StatusCodes.BAD_REQUEST){
                    response.json().then(resp=>{
                        const errorsServerSide = JSON.parse(resp.detail)
                        setOpenDeleteError(true);
                        setClientPlant(errorsServerSide.plants)
                        setContentTextErrorDialog(errorsServerSide.message)
                    })
                }else{
                    ToastService.error('Erreur durant la suppression de le client');
                }
            })
          }
        );
    }

    const getClientApp = (client: ClientSearch): string => {
        if (client.plantIds) {
            return `${appClientUrl}/home?plant_id=${client.plantIds[0]}&kc_idp_hint=microsoft`;
        } else {
            return '';
        }
    }

    const openInstallationView = (plantName: string): void => {
        navigate('/fleet/plant?plant_name=' + plantName);
    }

    const columns: Column[] = [
        {
        id: 'client',
        label: 'Client',
        description: 'Information du client',
        },
        {
        id: 'email',
        label: 'Email',
        description: 'Email associé au client',
        },
        {
        id: 'phone',
        label: 'Téléphone',
        description: 'Numéro de téléphone associé au client',
        },
        {
        id: 'actions',
        label: 'Actions',
        description: 'Action disponible'
        }
    ];

    return (
        <Grid container sx={{ backgroundColor: 'transparent',}}>
            <Card className='search-card'>
                <Grid container justifyContent="space-between" sx={{ backgroundColor: 'transparent'}}>
                    <Grid xs={12} sm={12} md={6} lg={9} item>
                        <SearchTextField
                            value={searchClient}
                            notifyOnClear={false}
                            width={'285px'}
                            placeholder="Par nom, prénom, email..."
                            onChange={onChange}
                            helper="La recherche doit contenir au moins 3 caractères."
                        ></SearchTextField>
                    </Grid>
                    <Grid xs={12} sm={12} md={6} lg={3} item style={{ paddingTop: (isMobile) ? 0 : 30, paddingBottom: (isMobile) ? 20 : 0}}>
                        <Button id="createClientButtonDialog" sx={{width: '100%'}} variant="outlined" onClick={handleAddNewClient} startIcon={<PersonAddIcon />}>Créer un nouveau client</Button>
                    </Grid>
                </Grid>
            </Card>

            <Card className='custom-card'>
                <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
                <Grid item xs={12} style={{ padding: '10px' }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
                    {rows.length === 0 &&
                        <CustomNoRowsOverlay noRowText="Effectuer une recherche pour trouver un client" loading={null}/>
                    }
                    {rows.length !== 0 &&
                        <TableContainer sx={{ boxShadow: 'none' }}>
                        <Table stickyHeader aria-label="sticky table">
                            {rows.length >= 30 &&
                            <caption>Seuls les 30 premiers résultats sont affichés, veuillez préciser votre recherche.</caption>
                            }
                            <TableHead>
                            <TableRow>
                                {columns.map((column, i) => (
                                <TableCell
                                    key={i}
                                    align='center'
                                    style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                >
                                    {column.label}
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((client) => (
                                <TableRow
                                key={client.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{padding:0}} align="center">
                                        <List>
                                            <ListItem>
                                                <ListItemText
                                                    primary={
                                                        <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            color="text.primary"
                                                        >
                                                            {client.firstName + ' ' + client.lastName.toUpperCase() + ' '}
                                                        </Typography>
                                                        { (client.aliasCompany) &&
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color={theme.palette.primary.main}
                                                            >

                                                                ({client.aliasCompany})
                                                            </Typography>
                                                        }
                                                        </React.Fragment>
                                                    }
                                                    secondary={client.address}
                                                />
                                            </ListItem>
                                        </List>
                                    </TableCell>
                                    <TableCell style={{padding:0}} align="center">{client.email}</TableCell>
                                    <TableCell align="center">{client.phone}</TableCell>
                                    <TableCell align="center" style={{width: '200px'}}>
                                        <Box>
                                            <Tooltip title={(client.plantIds?.length === 0) ? "Ce client ne possède aucune installation" : "Ouvrir l'application client"}>
                                                <span>
                                                    <IconButton
                                                        disabled={(client.plantIds?.length === 0)}
                                                        id="openClientApp"
                                                        aria-label="open update client"
                                                        edge="start"
                                                        href={getClientApp(client)}
                                                        sx={{
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <PhonelinkIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title="Modifier le client">
                                                <IconButton
                                                    id="updateClientButtonDialog"
                                                    color="secondary"
                                                    aria-label="open update client"
                                                    edge="start"
                                                    onClick={() => handleUpdateClient(client)}
                                                    sx={{
                                                        marginLeft: '13px',
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Supprimer le client">
                                                <IconButton
                                                    id="deleteClientButtonDialog"
                                                    color="error"
                                                    aria-label="open remove client"
                                                    edge="start"
                                                    onClick={() => handleRemoveClient(client)}
                                                    sx={{
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    }
                    </Paper>
                </Grid>
                </Grid>
            </Card>

            <DialogClient
                id={1}
                title={titleDialog}
                contentText={contentTextDialog}
                actionLabel={actionLabelDialog}
                clientSearchUpdated={(clientSearchUpdated !== undefined) ? clientSearchUpdated : null}
                open={open}
                handleUpdateRowClient={handleUpdateRowClient}
                handleClose={handleCloseDialogClient}
            />

            <Dialog open={openDeleteError} onClose={handleCloseDialogDeleteError} style={{ borderRadius: 22}}>
                <DialogTitle style={{padding:0}}>
                    <Toolbar>
                        <WarningIcon color="warning"/>
                        <Typography variant="h6" component="div"  sx={{ flexGrow: 1, marginTop: 0, marginLeft: 2 }}>
                            Impossible de supprimer le client
                        </Typography>
                        <IconButton
                            color="secondary"
                            style={{ background: 'rgba(53, 186, 7, 0.12)'}}
                            aria-label="close dialog"
                            edge="start"
                            onClick={handleCloseDialogDeleteError}
                            >
                            <CloseIcon style={{ color: 'black'}} />
                        </IconButton>
                    </Toolbar>
                </DialogTitle>
                <Divider style={{margin:0}}></Divider>
                <DialogContent>
                    <DialogContentText style={{marginBottom:20}}>
                        {contentTextErrorDialog}
                    </DialogContentText>
                    <List
                        sx={{ width: '100%', minWidth: 450}}
                        >
                        {clientPlant.map((plant, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <Button
                                            onClick={() => openInstallationView(plant.name)}
                                            style={{ marginRight:'-12px'}}
                                            variant="outlined">
                                            details
                                        </Button>
                                    }
                                >
                                    <ListItemIcon>
                                        <SolarPowerIcon />
                                    </ListItemIcon>
                                    <ListItemText sx={{ width: '100%', maxWidth: 250 }} id="switch-list-label-wifi" primary={plant.name} secondary={plant.plantAddress} />
                                </ListItem>
                            );
                        })}

                    </List>
                </DialogContent>
                <DialogActions style={{paddingRight: '24px', paddingBottom: '24px'}}>
                    <Button id="closeButtonClientPlantError" variant="outlined" color="inherit" onClick={handleCloseDialogDeleteError}>Fermer</Button>
                </DialogActions>
            </Dialog>

        </Grid>
    )
}