import { Agency } from "./Agency";

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string | null;
    role: string,
    agencies: Agency[],
}

export enum RolesTypes {
    ROLE_ADMIN = "Admin",
    ROLE_TECHNICIEN = "Technicien",
    ROLE_CHARGE_AFFAIRE_ADMIN = "Chargé d'affaire (Admin)",
    ROLE_CHARGE_AFFAIRE = "Chargé d'affaire",
    ROLE_COMMERCIAL_ADMIN = "Commercial (Admin)",
    ROLE_COMMERCIAL = "Commercial",
    ROLE_SAV_ADMIN = "SAV (Admin)",
    ROLE_SAV = "SAV",
    ROLE_INSTALLATOR = "Installateur prestataire"
}