import './App.css';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { DrawerHeader } from './Drawer';
import Menu from './Menu';
import PrivateRoutes from './PrivateRoutes';
import Footer from './Footer';
import Toast from './service/Toast';
import Loader from './service/Loader';
import ConfirmDialog from './service/ConfirmDialog';

import HomeView from './app/home/HomeView';

import ErrorBoundaryOverlay from './component/ErrorBoundaryOverlay'


function Layout() {
  const theme = useTheme();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className='layout'>
      <Box sx={{ display: 'flex' }}>
        <Menu />
        <div className="page-wrapper" style={{ backgroundColor: (theme.palette.mode === 'dark') ? 'black' : '#f3f1f1' }}>
          <CssBaseline />
          <Box component="main" style={{ paddingTop: '0px', marginTop: '-60px'}} sx={{ flexGrow: 1}}>
            <DrawerHeader />
            <ErrorBoundary
              FallbackComponent={ErrorBoundaryOverlay}
              onError={(error) => {
                // Optional: You can log the error to an error reporting service
                console.error(error);
              }}
              >
              <PrivateRoutes />
              {
                (pathname === '/') &&
                <HomeView></HomeView>
              }
            </ErrorBoundary>
          </Box>
        </div>
      </Box>
      <Toast/>
      <Loader/>
      <ConfirmDialog/>
      <Footer />
    </div>
  );
}

export default Layout;