import React, { useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import { DialogProps, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from '@mui/icons-material/Close';
import TransitionSlideUp from '../../../../../service/transition/TransitionSlideUp'

import immersionHeater from '../../../../../assets/immersionHeater/askoheat.png'

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import { ImmersionHeater } from "../../../../../interfaces/ImmersionHeater";
import ImmersionHeaterGeneral from "./ImmersionHeaterGeneral";
import ImmersionHeaterEquipment from "./ImmersionHeaterEquipment";
import ImmersionHeaterSetting from "./ImmersionHeaterSetting";


type ImmersionHeaterDialogProps = {
  immersionHeater: ImmersionHeater;
  open: boolean;
  handleClose: () => void;
}

export default function ImmersionHeaterDialog(props: ImmersionHeaterDialogProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [maxWidth] = useState<DialogProps['maxWidth']>('lg');

    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.handleClose();
    }

    return (
        <Dialog maxWidth={maxWidth} fullScreen={isMobile} open={props.open} onClose={handleClose} style={{borderRadius: 22}} TransitionComponent={TransitionSlideUp}>
            <DialogTitle style={{padding:0}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {props.immersionHeater.alias}
                    </Typography>
                    <IconButton
                        color="secondary"
                        style={{ background: 'rgba(53, 186, 7, 0.12)'}}
                        aria-label="close dialog"
                        edge="start"
                        onClick={props.handleClose}
                        >
                        <CloseIcon style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.common.black}} />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <Divider style={{margin:0}}></Divider>
            <DialogContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={5}>
                        <img alt='immersion heater' style={{width: '80%'}} src={immersionHeater} />
                    </Grid>
                    <Grid item xs={12} md={7} style={{height: '260px', padding:'0px', marginTop: (useMediaQuery(theme.breakpoints.down("lg"))) ? '0px' : '-50px'}}>
                        <TabContext value={value}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs tracker"
                            >
                                <Tab label="Informations" value="1" />
                                <Tab label="Équipements" value="2" />
                                <Tab label="Paramètres" value="3" />
                            </Tabs>
                            <TabPanel value="1" style={{padding:'10px'}}>
                               <ImmersionHeaterGeneral immersionHeater={props.immersionHeater}></ImmersionHeaterGeneral>
                            </TabPanel>
                            <TabPanel value="2" style={{padding:'10px'}}>
                               <ImmersionHeaterEquipment></ImmersionHeaterEquipment>
                            </TabPanel>
                            <TabPanel value="3" style={{padding:'10px'}}>
                               <ImmersionHeaterSetting></ImmersionHeaterSetting>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{paddingRight: '24px', paddingBottom: '24px'}}>
                <Button variant="outlined" color="inherit" onClick={props.handleClose}>Fermer</Button>
            </DialogActions>
        </Dialog>
    );
}