import dayjs from 'dayjs';

export function fillMissingDate(data: any[], expectedDates: string[]){
  const dates = data.map(x => x.date);
  for (const date of expectedDates){
    if (!dates.includes(date)){
      const dataElement = {...data[0]};
      Object.keys(dataElement).forEach((key) => dataElement[key] = null);
      dataElement.date = date;
      data.push(dataElement);
    }
  }
  data.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
  return data
}

/**
 * cette fonction enleve les artefacts sur un pas de 5 min pile
 * exemple : 10:35:00 OK
 * exemple : 10:35:02 NOK
 * exemple : 10:36:00 NOK
 * @param data
 * @returns data filtré
 */
export function removeUnnecessaryDate(data: any[]){
  return data.filter((currentData) => {
    const currentDate = new Date(currentData.date)
    return (currentDate.getMinutes() % 5 === 0 && currentDate.getSeconds() === 0)
  })
}