import { ReactNode } from 'react';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { Card, Link, Tooltip } from '@mui/material';
import { ListTrackersByAlarmItem } from '../../../../interfaces/ListTrackersByAlarmItem';
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppSelector } from '../../../../store/hooks';

type ListTrackersByAlarmTableProps = {
  value: Array<ListTrackersByAlarmItem>;
}

export default function ListTrackersByAlarmTable(props: ListTrackersByAlarmTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const apiRef = useGridApiRef()
  const alarmSelected = useAppSelector((state) => state.trackerAlarmSelected.value)

  function plantLink(plantId: number, value: string): ReactNode {
    return (
      <Tooltip title={value}>
        <Link
          color="secondary"
          href={`/scada/plant?plant_id=${plantId}`}
        >
          {value}
        </Link>
      </Tooltip>
    )
  }

  const columns: GridColDef<ListTrackersByAlarmItem>[] = [
    { field: 'plantName', headerName: 'Installation', width: 210, sortable: false, renderCell: (params) => plantLink(params.row.plantId, params.value) },
    { field: 'nTrk', headerName: 'Tracker', sortable: false, renderCell: (params) => plantLink(params.row.plantId, params.value) },
  ];

  let rows = props.value.filter(x => x.alarmTypeReference === alarmSelected)
  // add an id to each row
  rows = rows.map((x, i) => { return { ...x, id: i } })

  return (
    <>
    {
      alarmSelected &&
      <Card className='scada-card' style={{ flex: 1, marginLeft: (isMobile) ? '0px' : '20px', padding: "10px" }}>
        <div style={{ marginBottom: "5px", marginTop: "5px", textAlign: "center" }}><b>{alarmSelected.replace('TRACKER_', '')}</b></div>
        <DataGrid
          style={{ height: "85%", margin: "10px" }}
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          rowHeight={30}
          disableColumnMenu
          columnHeaderHeight={40}
          hideFooterSelectedRowCount
          initialState={{
            pagination: {
              paginationModel: { pageSize: 13, page: 0 },
            },
          }}
        />
      </Card>
      }
    </>
  );
}

