import apiSizea from '../../../../service/api/ApiSizea';
import { useEffect, useState } from 'react';
import {MenuItem, Select, SxProps} from "@mui/material";
import Simulation, {HotWaterTankTypeRef, ImmersionHeaterType} from "../model";
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';

type SelectImmersionHeaterTypeProps = {
    name:  `waterPointInputs.${number}.immersionHeaterTypeId`
    hotwatertanktype: HotWaterTankTypeRef
    register: UseFormRegister<Simulation>
    sx: SxProps
    labelId: string
    label: string
    setValue: UseFormSetValue<Simulation>
    defaultValue: string
}

export function SelectImmersionHeaterType(props: SelectImmersionHeaterTypeProps) {
    const [immersionHeaterTypes, setImmersionHeaterTypes] = useState<ImmersionHeaterType[]>([]);

    // récupérer les props du select en isolant la propriété register, qui sera étendu à part
    const {register, setValue, name, ...selectProps} = props;

    useEffect(() => {
        apiSizea.immersionHeaterTypes(props.hotwatertanktype).then(data => {
            setImmersionHeaterTypes(data);
        });
    }, [props.hotwatertanktype, name, setValue])

    if (immersionHeaterTypes.length === 0) {
        return <></>
    }

    return <Select {...selectProps} {...register(name, { required: true })}>
    {
        immersionHeaterTypes.map((immersionHeaterType: ImmersionHeaterType, idx) => <MenuItem key={idx} value={immersionHeaterType.id}>{immersionHeaterType.label}</MenuItem>)
    }
    </Select>
}
