import { useState } from 'react';

import { Grid } from '@mui/material'
import { Card } from '@mui/material'
import { Button } from '@mui/material'
import { IconButton } from '@mui/material'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";

import SearchTextField from '../../../component/form/SearchTextField';
import CustomNoRowsOverlay from '../../../component/CustomNoRowsOverlay';

import Box from '@mui/material/Box';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import DialogUser from './DialogUser';

import { User, RolesTypes } from '../../../interfaces/User';

import apiV2 from '../../../service/api/ApiV2';
import ConfirmDialogService from '../../../service/ConfirmDialogService';
import ToastService from '../../../service/ToastService';

export default function ListsUserSearchView() {

  interface Column {
    id: 'firstName' | 'lastName' | 'roles' | 'email' | 'phone' |'agencies' | 'actions';
    label: string;
    description: string,
    minWidth?: number;
    align?: 'center';
    format?: (value: number) => string;
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const [rows, setRow] = useState<Array<User>>([]);
  const [searchUser, setSearchUser] = useState<string>("");
  const [titleDialog, setTitleDialog] = useState<string>("");
  const [actionLabelDialog, setActionLabelDialog] = useState<string>("Créer");
  const [userModified, setUserModified] = useState<User | null>();
  const [contentTextDialog, setContentTextDialog] = useState<string>("");
  const [open, setOpen] = useState(false);

  const onChange = (searchedVal: string) => {
    apiV2.searchUsers(searchedVal).then(data => {
        setRow(data);
    })
    setSearchUser(searchedVal)
  };

  const handleAddNewUser = () => {
    setTitleDialog('Créer un nouvel utilisateur')
    setContentTextDialog('Déclarer un nouveau compte utilisateur. Cette opération va créer un utilisateur qui pourra accèder à l\'espace admin client.')
    setActionLabelDialog('Créer')
    setOpen(true);
  };

  const handleUpdateUser = (user: User) => {
    setTitleDialog('Modifier utilisateur')
    setContentTextDialog('Modifier l\'utilisateur ' + user.firstName + ' ' + user.lastName + ' en remplissant le formulaire suivant')
    setActionLabelDialog('Modifier')
    setOpen(true);
    setUserModified(user)
  };

  const handleUpdateRowUser = (user: User) => {
    setRow(rows.map((row) => {
      if (row.id === user.id) return user
      return row;
    }));
  };

  const handleRemoveUser = (user: User) => {
    ConfirmDialogService.warning(
      'md',
      'Supprimer l\'utilisateur',
      'Etes vous sur de vouloir supprimer l\'utilisateur ' + user.firstName + ' ' + user.lastName + ' ?',
      'Cette action est irréversible.',
      () => {
        apiV2.deleteUser(user.id).then(response => {
          if(response.ok){
            ToastService.success('L\'utilisateur ' + user.firstName + ' ' + user.lastName + ' a été supprimé')
            setRow(rows.filter((row) => row.id !== user.id));
          }else{
              ToastService.error('Erreur durant la suppression de l\'utilisateur');
          }
        })
      }
    );
  }

  const handleCloseDialogUser = () => {
    setOpen(false);
    setUserModified(null);
  };

  const columns: Column[] = [
    {
      id: 'firstName',
      label: 'Prénom',
      description: 'Prénom de l utilisateur',
    },
    {
      id: 'lastName',
      label: 'Nom',
      description: 'Nom de l utilisateur',
    },
    {
      id: 'roles',
      label: 'Rôles',
      description: 'Rôle(s) de l utilisateur sur la plateforme',
    },
    {
      id: 'agencies',
      label: 'Agences',
      description: 'Agence associé à l utilisateur',
    },
    {
      id: 'email',
      label: 'Email',
      description: 'Email associé à l utilisateur',
    },
    {
      id: 'phone',
      label: 'Téléphone',
      description: 'Numéro de téléphone associé à l utilisateur',
    },
    {
      id: 'actions',
      label: 'Actions',
      description: 'Action disponible'
    }
  ];

  return (
    <Grid container sx={{ backgroundColor: 'transparent',}}>
      <Card className='search-card'>
        <Grid container justifyContent="space-between" sx={{ backgroundColor: 'transparent'}}>
          <Grid item xs={12} sm={12} md={6} lg={9}>
            <SearchTextField
              value={searchUser}
              notifyOnClear={false}
              width={'285px'}
              placeholder="Par nom, prénom, email..."
              onChange={onChange}
              helper="La recherche doit contenir au moins 3 caractères."
            ></SearchTextField>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3} style={{ paddingTop: (isMobile) ? 0 : 30, paddingBottom: (isMobile) ? 20 : 0}}>
            <Button id="createUserButtonDialog" sx={{width: '100%'}} variant="outlined" onClick={handleAddNewUser} startIcon={<PersonAddIcon />}>Créer un nouvel utilisateur</Button>
          </Grid>
        </Grid>
      </Card>

      <Card className='custom-card'>
        <Grid container sx={{ backgroundColor: 'transparent',  pl:0 , pr: 0 }}>
          <Grid item xs={12} style={{ padding: '10px' }}>
            <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
              {rows.length === 0 &&
                <CustomNoRowsOverlay noRowText="Effectuer une recherche pour trouver un utilisateur" loading={null}/>
              }
              {rows.length !== 0 &&
                <TableContainer sx={{ boxShadow: 'none' }}>
                  <Table stickyHeader aria-label="sticky table">
                    {rows.length >= 30 &&
                      <caption>Seuls les 30 premiers résultats sont affichés, veuillez préciser votre recherche.</caption>
                    }
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell
                            key={i}
                            align='center'
                            style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">{row.firstName}</TableCell>
                          <TableCell align="center">{row.lastName.toUpperCase()}</TableCell>
                          <TableCell align="center">{ Object.values(RolesTypes)[Object.keys(RolesTypes).indexOf(row.role)]}</TableCell>
                          <TableCell align="center">
                            {row.agencies.map((agency, index) => (
                              <span key={index}>
                                { (index < 3) ? agency.name : '' }
                                { ((index < 3) && (row.agencies.length > index + 1)) &&
                                  <span>,&nbsp;</span>
                                }
                                { (index === 3) ? '+' + (row.agencies.length - 3) : ''}
                              </span>
                            ))}
                            { row.agencies.length === 0 &&
                              <span>Pas d'agence renseignée</span>
                            }
                          </TableCell>
                          <TableCell align="center">{row.email}</TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center" style={{width: '200px'}}>
                            <Box>
                              <IconButton
                                id="updateUserButtonDialog"
                                color="secondary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => handleUpdateUser(row)}
                                sx={{
                                  marginLeft: '10px',
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                id="deleteUserButtonDialog"
                                color="error"
                                aria-label="open drawer"
                                edge="start"
                                onClick={() => handleRemoveUser(row)}
                                sx={{
                                  marginLeft: '10px',
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </Paper>
          </Grid>
        </Grid>
      </Card>

      <DialogUser
        id={1}
        title={titleDialog}
        contentText={contentTextDialog}
        actionLabel={actionLabelDialog}
        user={(userModified !== undefined) ? userModified : null}
        handleUpdateRowUser={handleUpdateRowUser}
        open={open}
        handleClose={handleCloseDialogUser}
      />

    </Grid>
  )
}