import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

type CheckBoxFilterProps = {
    onChange: (isSelected: boolean) => void,
    value: boolean
    label: string
    style?: React.CSSProperties
}

export default function CheckBoxFilter(props: CheckBoxFilterProps) {

    return (
        <FormControlLabel
            control={<Checkbox
                checked={props.value}
                onChange={event => {props.onChange(event.target.checked)}}
                onClick={e => {e.stopPropagation()}}
                style={props.style}/>}
            label={props.label}
        />
    )
}