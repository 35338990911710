import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Grid, useTheme, Divider, Card, CardContent, Toolbar } from '@mui/material';
import { faFileExcel, faFileWord } from '@fortawesome/free-solid-svg-icons';
import sizeaApi from '../../../service/api/ApiSizea';
import DownloadFile from './component/DownloadFile';

export default function SimulationList() {
  const theme = useTheme()
  const [searchParams] = useSearchParams();
  const simulationId = searchParams.get('simulation-id');
  const simulationName = searchParams.get('simulation-name');
  const filename = searchParams.get('filename');

  useEffect(() => {
    if (null === simulationId) return
    if (null === filename) return

    sizeaApi.getSimulationFile(`${simulationId}.xlsx`, `${filename}.xlsx`)
    sizeaApi.getSimulationFile(`${simulationId}.docx`, `${filename}.docx`)
  }, [simulationId, filename])

  if (simulationId === null || filename === null) {
    return (
      <Card className='custom-card'>
        <CardContent>
          <Grid container justifyContent={"center"} direction={"row"} style={{ color: theme.palette.error.main }}>
            Le simulation-id n'est pas dans l'url, cette variable est requise pour le fonctionnement de cette page
          </Grid>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className='custom-card'>
      <Toolbar>
        Page de téléchargement de la simulation &nbsp;<b>{simulationName}</b>
      </Toolbar>
      <Divider style={{ marginTop: '0px' }} />
      <CardContent>
        <Grid container justifyContent={"center"} direction={"row"}>
          Si le téléchargement ne démarre pas automatiquement, vous pouvez cliquer sur les liens suivants:
          <Grid item xs={12}>
            <DownloadFile title='Fichier EXCEL' file={`${simulationId}.xlsx`} faIcon={faFileExcel} type={'simulation'} />
            <DownloadFile title='Fichier WORD' file={`${simulationId}.docx`} faIcon={faFileWord} type={'simulation'} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
