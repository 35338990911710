import dayjs from "dayjs"

export function formatDate(date: string|null): string {
    if(date === null) {
        return 'N\'A'
    }

    return dayjs(date).tz(dayjs.tz.guess()).format('DD/MM/YYYY HH:mm')
}

export function formatDateShort(date: string|null): string {
    if(date === null) {
        return 'N\'A'
    }

    return dayjs(date).tz(dayjs.tz.guess()).format('D MMMM YYYY')
}

export function nbDayBetweenToday(date: string|null): string {
    if(date === null) {
        return 'N\'A'
    }

    const date1 = dayjs(date);
    const date2 = dayjs();
    const days = date2.diff(date1, 'days')

    return days.toString()
}