import TemporarilyUnavailableCard from '../TemporarilyUnavailableCard'

import { Ess } from '../../../../interfaces/ess/Ess'

interface propsInterface {
    ess: Ess;
}

export default function EssCard(props: propsInterface) {
    return (
        <TemporarilyUnavailableCard title={'ESS ' + props.ess.name + ' - ' + props.ess.serialNumber}></TemporarilyUnavailableCard>
    );
}