
import { FileSearch } from '../interfaces/documentation/FileSearch';
import { REACT_APP_DIRECTUS_URL } from "../config";

const baseUrlApiDirectus = REACT_APP_DIRECTUS_URL;

const DirectusService = {
    openNewTab: function(file: FileSearch): void {
        window.open(baseUrlApiDirectus + '/' + file.id, '_blank')
    },
    getUrlDownload: function(file: FileSearch): string {
        return baseUrlApiDirectus + '/assets/' + file.id  + '?download'
    }
}

export default DirectusService;