import { TextField } from "@mui/material";
import { DatePicker as XDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from "react";

interface Props {
  label: string
  onChange: CallableFunction
}
export function DatePicker(props: Props) {
  const [date, setDate] = useState<Dayjs | null>(null);

  return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
    <XDatePicker
      label={props.label}
      onChange={(event) => {
        if (null !== event) {
          props.onChange(dayjs(event).utc());
          setDate(dayjs(event))
        } else {
          props.onChange(null)
          setDate(null)
        }
      }}
      renderInput={(inputProps) => (
        <TextField
          style={{width: '100%'}}
          {...inputProps} />
      )}
      value={date}
    />
  </LocalizationProvider>
}
