import React, {ForwardRefRenderFunction, RefObject, useImperativeHandle, useState} from "react";
import { ReactNode } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import CircularProgress from '@mui/material/CircularProgress';

type CardRowImperativeHandler = {
    setIsEditing: (value: boolean) => void;
};

type CardRowProps = {
    label: string;
    children: ReactNode;
    isEditable?: boolean;
    childrenEdit?: ReactNode;
    chidrenEditValidate?: () => void;
    cardRef?: RefObject<CardRowImperativeHandler>;
}

const CardRow: ForwardRefRenderFunction<CardRowImperativeHandler, CardRowProps> = (props: CardRowProps) => {

    const trackers = useSelector((state: RootState) => state.trackers);
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleValdiate = () => {
        if(props.chidrenEditValidate) props.chidrenEditValidate();
    };

    //permet d appeler setIsEditing depuis le composant parent
    useImperativeHandle(props.cardRef, () => ({
        setIsEditing(value: boolean) {
            setIsEditing(value);
        },
    }));

    return <Grid container sx={{mt: "10px"}}>
        <Grid item xs={5} md={7} className="item-feature">
            {props.label}
        </Grid>
        <Grid item xs={5} md={4}>
            {
                (props.isEditable && isEditing) ? props.childrenEdit : props.children
            }
        </Grid>
        <Grid item xs={2} md={1}>
            {
                (props.isEditable && !isEditing) &&
                    <IconButton
                        style={{ marginTop: '-10px'}}
                        color="secondary"
                        edge="start"
                        size="small"
                        onClick={handleEdit}
                        >
                        <EditIcon/>
                    </IconButton>
            }
            {
                (props.isEditable && isEditing) &&
                    <Box>
                        <Grid container style={{ marginLeft: '10px'}}>
                            <Grid item xs={6} style={{ paddingRight: '10px'}}>
                                <Tooltip title="Valider la modification">
                                    <IconButton
                                        color="primary"
                                        edge="start"
                                        size="small"
                                        onClick={handleValdiate}
                                    >
                                        {
                                            (trackers.loading) ?
                                                <CircularProgress style={{marginRight: '5px'}} size={22}/>
                                            :
                                                <DoneIcon/>
                                        }
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <Tooltip title="Annuler la modification">
                                    <IconButton
                                        edge="start"
                                        size="small"
                                        onClick={() => {setIsEditing(false)}}
                                        >
                                        <CloseIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
            }
        </Grid>
    </Grid>
}

export default React.forwardRef(CardRow);