import { Bar, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Card, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRef } from "react";
import { ListTrackersByAlarmItem } from '../../../../interfaces/ListTrackersByAlarmItem';
import { eventLevelColor } from '../../plant/AlarmService';
import { useAppDispatch } from '../../../../store/hooks';
import { setTrackerAlarmSelected } from '../../../../store/scada/plants/TrackerAlarmSelectedSlice';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip,
);

type CountTrackersByAlarmProps = {
  value: Array<ListTrackersByAlarmItem>;
}

type AlarmType = {
  alarmTypeLevel: number;
  alarmTypeLabel: string;
  alarmTypeReference: string;
}

type CountTrackersByAlarmItem = AlarmType & {
  nbrTrackers: number;
}

function countTrackersByAlarmChart(data: Array<ListTrackersByAlarmItem>): CountTrackersByAlarmItem[] {
  const uniqueAlarmType: Set<AlarmType> = new Set();
  const res: CountTrackersByAlarmItem[] = []
  const alarmTypeReferences: string[] = []
  data.forEach(obj => {
    if (!alarmTypeReferences.includes(obj.alarmTypeReference)) {
      const row = { alarmTypeReference: obj.alarmTypeReference, alarmTypeLevel: obj.alarmTypeLevel, alarmTypeLabel: obj.alarmTypeLabel }
      uniqueAlarmType.add(row)
      alarmTypeReferences.push(obj.alarmTypeReference)
    }
  })
  uniqueAlarmType.forEach((obj) => {
    const nbrTrackers = data.filter(x => x.alarmTypeLabel === obj.alarmTypeLabel).length
    res.push({
      nbrTrackers: nbrTrackers,
      alarmTypeLevel: obj.alarmTypeLevel,
      alarmTypeLabel: obj.alarmTypeLabel,
      alarmTypeReference: obj.alarmTypeReference
    })
  })

  return res
}

export default function CountTrackersByAlarmChart(props: CountTrackersByAlarmProps) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const chartRef = useRef();
  const dispatch = useAppDispatch();

  const countTrackers = countTrackersByAlarmChart(props.value)

  // sort data in descending order
  const sortedData = countTrackers.sort((x, y) => y.nbrTrackers - x.nbrTrackers)

  function onClick(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
    if (!chartRef.current) {
      return
    }
    const element = getElementAtEvent(chartRef.current, event)
    if (element.length === 0) {
      return
    }
    dispatch(setTrackerAlarmSelected(sortedData[element[0].index].alarmTypeReference))
  }

  const backgroundColors = sortedData.map(x => eventLevelColor(x.alarmTypeLevel, theme));

  const labels = sortedData.map(x => x.alarmTypeReference.replace('TRACKER_', ''))
  const data = {
    labels,
    datasets: [
      {
        data: sortedData.map(x => x.nbrTrackers),
        backgroundColor: backgroundColors
      },
    ],
  }

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    plugins: {
      title: {
        display: true,
        text: 'Nombre de trackers par alarme',
        font: {
          size: 16,
        }
      },
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return sortedData[tooltipItem.dataIndex].alarmTypeLabel
          },
          afterLabel: function (tooltipItem) {
            return tooltipItem.dataset.data[tooltipItem.dataIndex] + " trackers";
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          display: false,
        }
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false
        }
      }
    }
  }

  return (
    <Card className='scada-card' style={{ flex: 1, overflow: "hidden", marginLeft: (isMobile) ? '0px' : '20px', padding: "10px" }}>
      <Bar options={options} data={data} ref={chartRef} onClick={onClick} />
    </Card>
  )
}
