import React, { useRef, useState, useEffect } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import type { DialogProps } from "@mui/material";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from '@mui/icons-material/Close';

import UserForm from './UserForm';
import TransitionSlideUp from '../../../service/transition/TransitionSlideUp'

import { User } from '../../../interfaces/User';

type DialogUserProps = {
  id: number;
  title: string;
  actionLabel: string;
  contentText?: string,
  open: boolean;
  handleClose: () => void;
  handleUpdateRowUser: (user: User) => void;
  user: User | null,
}

export default function DialogUser(props: DialogUserProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

    type UserHandle = React.ElementRef<typeof UserForm>;

    const formUserRef = useRef<UserHandle>(null);
    const [isValidInput, setIsValidInput] = useState<boolean>(false);

    const handleCreate = () => {
        if (formUserRef && formUserRef.current) {
            formUserRef.current.submitForm();
        }
    };

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        props.handleClose();
    }

    useEffect(()=>{
        if(isValidInput) {
            setTimeout(() => {
                props.handleClose()
                setIsValidInput(false)
            }, 500);
        }
    },[isValidInput, props])

    return (
        <Dialog fullScreen={isMobile} open={props.open} onClose={handleClose} style={{borderRadius: 22}} TransitionComponent={TransitionSlideUp}>
            <DialogTitle style={{padding:0}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                    <IconButton
                        color="secondary"
                        style={{ background: 'rgba(53, 186, 7, 0.12)'}}
                        aria-label="close dialog"
                        edge="start"
                        onClick={props.handleClose}
                        >
                        <CloseIcon style={{ color: 'black'}} />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <Divider style={{margin:0}}></Divider>
            <DialogContent>
                <DialogContentText style={{marginBottom:20}}>
                    {props.contentText}
                </DialogContentText>
                <UserForm formRef={formUserRef} setIsValidInput={setIsValidInput} userModified={props.user} handleUpdateRowUser={props.handleUpdateRowUser}/>
            </DialogContent>
            <DialogActions style={{marginTop: '-25px', paddingRight: '24px', paddingBottom: '24px'}}>
                <Button variant="outlined" color="inherit" onClick={props.handleClose}>Annuler</Button>
                <Button id="createUserButtonForm" variant="outlined" onClick={handleCreate}> {props.actionLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}