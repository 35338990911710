import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TrackerType } from '../../interfaces/plant/TrackerType';
import apiV2 from '../../service/api/ApiV2';

interface trackerTypesState {
  data: TrackerType[];
  loading: boolean;
  error: string | null;
}

const initialState: trackerTypesState = {
  data: [],
  loading: false,
  error: null,
};

export const fetchTrackerTypes = createAsyncThunk(
  'trackerTypes/fetchTrackerTypes', async () => {
  return apiV2.trackerTypes()
});

const trackerTypesSlice = createSlice({
  name: 'trackerTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackerTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTrackerTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchTrackerTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      });
  },
});

export default trackerTypesSlice.reducer;