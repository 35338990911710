import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CheckpointResult } from '../../interfaces/control/Checkpoint';
import { CheckpointBlock } from '../../interfaces/control/CheckpointBlock';
import { CheckpointExecute } from '../../interfaces/control/CheckpointExecute';
import { UploadFile } from 'antd/es/upload';
import ToastService from '../../service/ToastService';
import apiGateway from '../../service/api/ApiGateway';

interface CheckpointsBlockState {
  data: CheckpointBlock[];
  loading: boolean;
  error: string | null;
  unprocessedMercureMessages: CheckpointResult[];
}

const initialState: CheckpointsBlockState = {
  data: [],
  loading: false,
  error: null,
  unprocessedMercureMessages: [],
};

const updateStateCheckpointResult = (state: CheckpointsBlockState, checkpointResult: CheckpointResult): void => {
  state.data.forEach((checkpointBlock: CheckpointBlock) => {
    checkpointBlock.checkpoints.forEach((checkpoint) => {
      if (checkpoint.result) {
        if (checkpoint.result.id === checkpointResult.id) {
          checkpoint.result = checkpointResult
        }
      }
    });
  })
}

export const fetchCheckpointsBlock = createAsyncThunk(
  'checkpointBlock/fetchCheckpointsBlock', async (plantId: number) => {
  return apiGateway.apiControl.checkpointsBlocks(plantId)
});

export const fetchCheckpointsExecuteAll = createAsyncThunk(
  'checkpointBlock/fetchCheckpointsExecuteAll', async (plantId: number) => {
  return apiGateway.apiControl.checkpointsExecuteAll(plantId)
});

export const fetchCheckpointResultExecute = createAsyncThunk(
  'checkpointBlock/fetchCheckpointResultExecute', async (checkpointExecute: CheckpointExecute) => {
  return apiGateway.apiControl.checkpointResultExecute(checkpointExecute)
});

export const fetchCheckpointExecuteSoutirage = createAsyncThunk(
  'checkpointBlock/fetchCheckpointExecuteSoutirage', async (arg: [number, UploadFile]) => {
  return apiGateway.apiControl.checkpointExecuteSoutirage(arg[0], arg[1])
});

export const updateCheckpointResult = createAsyncThunk(
  'checkpointBlock/updateCheckpointResult', async (result: CheckpointResult) => {
  return apiGateway.apiControl.updateCheckpointResult(result)
});

export const updateCheckpointBlockResultComment = createAsyncThunk(
  'checkpointBlock/updateCheckpointBlockResultComment', async (checkpointBlock: CheckpointBlock) => {
  return apiGateway.apiControl.updateCheckpointBlockResultComment(checkpointBlock)
});

const processMercureMessage = (state: any, action: any): void => {
  let found = false;
  state.data.forEach((checkpointBlock: CheckpointBlock) => {
    checkpointBlock.checkpoints.forEach((checkpoint) => {
      if (checkpoint.result) {
        if (checkpoint.result.id === action.payload.id){
          checkpoint.result = action.payload;
          found = true;
        }
      }
    });
  });
  if (!found) {
    // checkpoint not found in state, we store it to process it later,
    // when we will get backend response to fetchCheckpointsExecuteAll endpoint
    state.unprocessedMercureMessages.push(action);
  }
}

const checkpointsBlockSlice = createSlice({
  name: 'CheckpointBlock',
  initialState,
  reducers: {
    updateCheckpointResultState: processMercureMessage
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckpointsBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCheckpointsBlock.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload
      })
      .addCase(fetchCheckpointsBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(fetchCheckpointsExecuteAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCheckpointsExecuteAll.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload

        // We have all checkpoint list now, process Mercure messages
        state.unprocessedMercureMessages.forEach(mercurePaylod => {
          processMercureMessage(state, mercurePaylod)
        });
      })
      .addCase(fetchCheckpointsExecuteAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
        ToastService.error(state.error);
      })
      .addCase(fetchCheckpointExecuteSoutirage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCheckpointExecuteSoutirage.fulfilled, (state, action) => {
        state.loading = false;
        updateStateCheckpointResult(state, action.payload)
      })
      .addCase(fetchCheckpointExecuteSoutirage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(fetchCheckpointResultExecute.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCheckpointResultExecute.fulfilled, (state, action) => {
        state.loading = false;
        updateStateCheckpointResult(state, action.payload)
      })
      .addCase(fetchCheckpointResultExecute.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'An error occurred';
      })
      .addCase(updateCheckpointResult.fulfilled, (state, action) => {
        state.loading = false;
        updateStateCheckpointResult(state, action.payload)
      })
      .addCase(updateCheckpointBlockResultComment.fulfilled, (state, action) => {
        state.loading = false;
        state.data.forEach((checkpointBlock) => {
          if(checkpointBlock.id === action.payload.id) checkpointBlock = action.payload
        })
      });
  },
});

export const { updateCheckpointResultState } = checkpointsBlockSlice.actions;


export default checkpointsBlockSlice.reducer;