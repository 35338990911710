import { Line } from 'react-chartjs-2';
import { mergeObjectsRecursive } from '../../../../utils/utils';
import { useEffect, useState, useRef } from 'react';
import { fillMissingDate } from '../ParseRawData';
import { Plant } from '../../../../interfaces/Plant';
import { ImmersionHeater } from '../../../../interfaces/ImmersionHeater';
import { Tracker } from '../../../../interfaces/Tracker';
import { Dayjs } from 'dayjs';
import { formatXLabels, generateDates } from '../DateService';
import apiV2 from '../../../../service/api/ApiV2';
import { useTheme, alpha } from '@mui/material';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { PowerPlant } from '../../../../interfaces/PowerPlant';
import { PowerInverter } from '../../../../interfaces/PowerInverter';
import { PowerImmersionHeater } from '../../../../interfaces/PowerImmersionHeater';
import { forkJoin } from 'rxjs';
import { CrosshairPlugin } from '../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair';
import { ResizableBox } from 'react-resizable'
import "react-resizable/css/styles.css";

import GraphResizeService  from '../../../../service/GraphResizeService'

type PowerChartProps = {
    options: ChartOptions<'line'>;
    plant: Plant;
    immersionHeaters: ImmersionHeater[];
    trackers: Tracker[];
    startDate: Dayjs;
    endDate: Dayjs;
}

const defaultOptions: ChartOptions<'line'> = {
    plugins: {
        title: {
            display: true,
            text: 'Puissance (kW)',
        },
    },
};

function convert_w_to_kw(power: number){
    if (power === null) return null;
    return Math.round(power / 1000 * 100) / 100 // two digits after comma
}

export function PowerChart(props: PowerChartProps) {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement|null>(null)
    const options = mergeObjectsRecursive(defaultOptions, props.options)
    const [chartData, setChartData] = useState<ChartData<'line'>>({labels:[], datasets: []});

    const [width, setWidth] = useState(0)

    useEffect(() => {
        GraphResizeService.checkResizeWidth(ref, width, setWidth)
      },
    [ref, width, setWidth])

    useEffect(() => {
        const expectedDates = generateDates(props.startDate, props.endDate)

        async function updatePlantDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const powerPlant = await apiV2.powerPlant(props.startDate, props.endDate, props.plant.id);
            fillMissingDate(powerPlant, expectedDates);
            datasets.push(
                {
                    label: 'Production totale',
                    data: powerPlant.map((x: PowerPlant) => convert_w_to_kw(x.production)),
                    borderColor: theme.palette.plantProductionCurve,
                    backgroundColor: alpha(theme.palette.plantProductionCurve, 0.5),
                    borderWidth: 2,
                    fill: 'origin',
                    tension: 0.4,
                    order: 1000, // used to display curves in specific order. This is a big value so that prod of tracker and imh will be drawn above this curve
                })
            datasets.push(
                {
                    label: 'Consommation',
                    data: powerPlant.map((x: PowerPlant) => convert_w_to_kw(x.consumption)),
                    borderColor: theme.palette.plantConsumptionCurve,
                    backgroundColor: alpha(theme.palette.plantConsumptionCurve, 0.5),
                    borderWidth: 2,
                    fill: 'origin',
                    tension: 0.4,
                    order: 1001, // used to display curves in specific  order. This is a big value so that prod of tracker and imh will be drawn above this curve
                }
            )
        }

        async function updateTrackersDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const nbColors = theme.palette.inverterProductionCurves.length;
            const tasks = []
            for (const tracker of props.trackers) {
                tasks.push(apiV2.powerInverter(props.startDate, props.endDate, tracker.id));
            }
            await Promise.all(tasks).then((results) => {
                for (let i = 0; i < results.length; i++) {
                    fillMissingDate(results[i], expectedDates);
                    datasets.push(
                        {
                            label: `Production tracker ${props.trackers[i].nTrk}`,
                            data: results[i].map((x: PowerInverter) => convert_w_to_kw(x.production)),
                            borderColor: theme.palette.inverterProductionCurves[i % nbColors],
                            backgroundColor: theme.palette.inverterProductionCurves[i % nbColors],
                            borderWidth: 2,
                            tension: 0.4,
                        });
                }
            });
        }

        async function updateImmersionHeatersDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const nbColors = theme.palette.inverterProductionCurves.length;
            const tasks = []
            for (const immersionHeater of props.immersionHeaters) {
                tasks.push(apiV2.powerImmersionHeater(props.startDate, props.endDate, immersionHeater.id));
            }
            await Promise.all(tasks).then((results) => {
                for (let i = 0; i < results.length; i++) {
                    fillMissingDate(results[i], expectedDates);
                    datasets.push(
                        {
                            label: `Consommation thermoplongeur ${i+1}`,
                            data: results[i].map((x: PowerImmersionHeater) => convert_w_to_kw(x.consumption)),
                            borderColor: theme.palette.immersionHeaterConsumptionCurves[i % nbColors],
                            backgroundColor: theme.palette.immersionHeaterConsumptionCurves[i % nbColors],
                            borderWidth: 2,
                        });
                }
            });
        }
        const datasets: ChartDataset<'line'>[] = [];
        const waitForDatasets = forkJoin({
            plantDatasets: updatePlantDatasets(datasets),
            trackersDatasets: updateTrackersDatasets(datasets),
            immersionHeatersDatasets: updateImmersionHeatersDatasets(datasets),
            });
        waitForDatasets.subscribe({
            next: () =>
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: datasets
                }),
            error: () => {
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: []
                })
            }
        })
    }, [props, theme])

    const chartHeight = 400

    return (
        <div ref={ref} className='scada-card-zoom' >
            <ResizableBox  transformScale={0.75} className="box" width={width} height={chartHeight} resizeHandles={['s']} minConstraints={[100, chartHeight]} maxConstraints={[width, chartHeight*2]}>
            {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
                <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: '15px'}}/>
            </ResizableBox>
        </div>
    )
}
