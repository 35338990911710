import { useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';

import apiV2 from '../../service/api/ApiV2';
import { Agency } from '../../interfaces/Agency';

type SelectProps = {
  required: boolean;
  preloadAgencies?: Agency[];
  onChange: (value: Agency[]) => void;
  helper?: string;
  errors?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
};

export default function SelectAgencies(props: SelectProps) {
    const [agenciesList, setAgenciesList] = useState<Agency[]>([]);
    const [agenciesSelectedIds, setAgenciesSelectedIds] = useState<number[]>([]);

    useEffect(() => {
        if(props.preloadAgencies) {
            setAgenciesSelectedIds(props.preloadAgencies.map(agency => agency.id));
        }
     }, [props.preloadAgencies]);

    useEffect(() => {
        apiV2.agencies().then(setAgenciesList)
    }, []);

    useEffect(() => {
        if(agenciesList.length > 0){
            props.onChange(getAgencyByIds(agenciesSelectedIds))
        }
      }, [agenciesSelectedIds])

    const getAgencyByIds = (agenciesIds: number[]) => {
        const agenciesListSelected: Agency[] = []
        agenciesIds.forEach(agencyId => {
            agenciesList.forEach(agency => {
                if(agency.id === agencyId) agenciesListSelected.push(agency)
            })
        })
        return agenciesListSelected
    }

    const isSelectAllAgencies = () => {
        return (agenciesSelectedIds.length >= agenciesList.length)
    }

    const handleChange = (event: SelectChangeEvent<number[]>)  => {
        const value = event.target.value as number[];
        if(value.includes(-1)){
            if (isSelectAllAgencies()){
                setAgenciesSelectedIds([])
            }else{
                setAgenciesSelectedIds(agenciesList.map(agency => agency.id))
            }
        }else{
            setAgenciesSelectedIds(value);
        }
    }

    const getChipAgenciesSelected = () => {
        const chips: JSX.Element[] = []
        agenciesList.forEach((agency) => {
            agenciesSelectedIds.forEach((agcenyId, index) => {
                if(agency.id === agcenyId && index <= 3) chips.push(
                    <Chip
                        key={agency.id}
                        style={{marginTop: '-5px',  marginRight: '5px' }}
                        label={(index === 3) ? '+' + (agenciesSelectedIds.length - index) : agency.name}
                    />
                    )
                })
            })
        return chips
    }

    return (
        <div>
            <InputLabel id="selectAgenciesLabel" color='primary' error={props.errors} required={props.required}>Agences</InputLabel>
            <Select
                labelId="selectAgenciesLabel"
                id="selectAgencies"
                value={agenciesSelectedIds}
                multiple
                fullWidth
                label="Agences"
                color='primary'
                onChange={handleChange}
                error={props.errors}
                input={<OutlinedInput label="Agences" />}
                renderValue={() => (
                    <Box>
                        { getChipAgenciesSelected() }
                    </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    <MenuItem value={-1}>
                        <Checkbox checked={isSelectAllAgencies()} />
                        <ListItemText primary='Toutes les agences' />
                    </MenuItem>
                    <Divider style={{margin:0}}></Divider>
                    {agenciesList.map((agency) => (
                    <MenuItem key={agency.id} value={agency.id}>
                        <Checkbox checked={agenciesSelectedIds.includes(agency.id)} />
                        <ListItemText primary={agency.name} />
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
