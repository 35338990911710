import EssCard from "./EssCard";
import {Ess} from "../../../../../interfaces/ess/Ess";

type EssCardsProps = {
    ess: {isSelected: boolean, ess: Ess}[];
}

export function EssCards(props: EssCardsProps) {
    return (
        <>
            {
                props.ess.map(ess => {
                    return (
                        <EssCard
                            key={ess.ess.id}
                            ess={ess.ess}
                            isSelected={ess.isSelected}
                        />
                    )
                })
            }
        </>
    )
}