import Box from '@mui/material/Box';
import PrivateRoutes from '../../PrivateRoutes';

function PlatformAdministration() {
  return (
    <Box sx={{ display: 'flex' }}>
      <PrivateRoutes authField='authAppPlatformAdministration'/>
    </Box>
  );
}

export default PlatformAdministration;
