export const URI_AGENCY = '/v2/human/agencies/'

interface User {
    id: number | null,
    firstname: string,
    lastname: string,
    email: string,
    phone: string | null,
    role: string
    agencies: string[]
}

export default User;