import sizeaApi from '../../../../service/api/ApiSizea'
import { useEffect, useState } from 'react';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, SxProps, Tooltip, useTheme } from "@mui/material";
import { Control, Controller } from 'react-hook-form';
import DownloadIcon from '@mui/icons-material/Download';
import Simulation, { Template } from '../model';

type SelectTemplateProps = {
    name: 'template'
    label: string
    labelId: string
    control: Control<Simulation>
    error?: boolean
    sx: SxProps
}

export function SelectTemplate(props: SelectTemplateProps) {
    const theme = useTheme();
    const [templates, setTemplates] = useState<Template[]>([]);

    useEffect(() => {
        sizeaApi.getTemplates().then(data => {
            setTemplates(data);
        });
    }, [])

    const { name, control, error } = props;

    return <FormControl >
        <FormLabel required style={{ color: error ? theme.palette.error.main : '' }} id="file-type-label">
            Type de modèle
        </FormLabel>
        <Controller
            rules={{ required: true }}
            control={control}
            name={name}
            render={({ field }) => (
                <RadioGroup {...field} id={`consumptionFileTypeEnum-select`} style={{ marginLeft: 15 }} >
                    {templates.map((essType: Template, idx) => {
                        return <div key={idx}>
                            <FormControlLabel
                                value={essType.name}
                                control={<Radio />}
                                label={essType.name}/>
                            <Tooltip title="Télécharger le modèle">
                                <Button onClick={() => { sizeaApi.getTemplateFile(essType.name) }}>
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    })}
                    <FormControlLabel key={'custom'} value={'custom'} control={<Radio />} label={'Personnalisé'} />
                </RadioGroup>
            )}
        />
    </FormControl>
}
