import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import apiV2 from '../../../../../service/api/ApiV2';
import ToastService from '../../../../../service/ToastService';
import ConfirmDialogService from '../../../../../service/ConfirmDialogService';

import { StatusCodes } from 'http-status-codes';

import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

interface propsInterface {
    label: string;
    value: string;
    trackerId: number;
}

const mappingActions: { [key: string]: (trackerId: number) => Promise<Response> } = {
    "restart_tracking": apiV2.restartTracking,
    "start_ssh": apiV2.startSSH,
    "stop_ssh": apiV2.stopSSH,
    "start_flat": apiV2.startFlat,
    "stop_flat": apiV2.stopFlat,
    "tracker_alarm_end_collection": apiV2.trackerAlarmEnd,
};

export function TrackerAction(props: propsInterface) {
    const handleClickOpen = () => {
        ConfirmDialogService.warning(
            'md',
            props.label,
            'Etes-vous sur de vouloir executer cette commande ?',
            'Cette action va être envoyé au tracker.',
            () => {
                mappingActions[props.value](props.trackerId).then(response => {
                    if(response.status === StatusCodes.ACCEPTED){
                        ToastService.success('Demande à distance envoyée, la commande peut prendre plusieurs minutes');
                    }
                });
            }
          );
    };

    return <Grid container sx={{mt: "10px"}}>
        <Grid item xs={7} className="item-feature">
            {props.label}
        </Grid>
        <Grid item xs={5}>
            <Button variant='outlined' size='small' onClick={handleClickOpen} endIcon={<SettingsRemoteIcon />}>
                Envoyer
            </Button>
        </Grid>
    </Grid>
}

