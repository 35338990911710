import { configureStore } from '@reduxjs/toolkit'
import plantObjectsReducer from './scada/plant/PlantObjectsSlice'
import scadaFilterValuesReducer from './scada/plants/ScadaFilterValuesSlice'
import trackerAlarmSelectedReducer from './scada/plants/TrackerAlarmSelectedSlice'

import themeReducer from './theme/theme.store'

import agenciesReducer from './form/agencies.store'
import installatorsReducer from './form/installators.store'
import trackerTypesReducer from './form/trackerTypes.store'

import trackersReducer from './scada/tracker/trackers.store'

import authorizationReducer from './auth/authorization.store'

import checkpointsBlockReducer from './control/checkpointsBlock.store'

import pvPanelTypesReducer from './form/pvPanelTypes.store'

export const store = configureStore({
  reducer: {
    plantObjects: plantObjectsReducer,
    scadaFilterValues: scadaFilterValuesReducer,
    trackerAlarmSelected: trackerAlarmSelectedReducer,
    agencies: agenciesReducer,
    installators: installatorsReducer,
    trackerTypes: trackerTypesReducer,
    authorization: authorizationReducer,
    checkpointsBlock: checkpointsBlockReducer,
    theme: themeReducer,
    trackers: trackersReducer,
    pvPanelTypes: pvPanelTypesReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch