import { Meter } from "../../../../../interfaces/Meter";
import CardRow from "../CardRow";

interface propsInterface {
    meter: Meter;
}

export default function MeterSetting(props: propsInterface) {

    return (
        <div>
            <h4>Configuration logiciel</h4>

            <CardRow label="Type de connexion">
                {props.meter.terminal}
            </CardRow>

            <CardRow label="Protocole de communication" >
                {props.meter.protocol}
            </CardRow>

            <CardRow label="Adresse" >
                {props.meter.address}
            </CardRow>

        </div>
    );
}