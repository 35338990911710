import React, { useRef, useState } from "react";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';

import type { DialogProps } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";

import CloseIcon from '@mui/icons-material/Close';
import PlantForm from "./PlantForm";
import TransitionSlideUp from '../../../service/transition/TransitionSlideUp'

import { PlantSearch } from '../../../interfaces/PlantSearch';

type DialogClientProps = {
  title: string;
  actionLabel: string;
  contentTextStep1: string,
  contentTextStep2: string,
  plantSearchUpdated: PlantSearch | null,
  handleUpdateRowPlant: (client: PlantSearch) => void;
  open: boolean;
  handleClose: () => void;
}

const steps = ['Informations', 'Equipements'];

export default function DialogUser(props: DialogClientProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const [activeStep, setActiveStep] = React.useState(0);
    const [isValidInput, setIsValidInput] = useState<boolean>(false);

    type PlantHandle = React.ElementRef<typeof PlantForm>;
    const formPlantRef = useRef<PlantHandle>(null);

    const handleAction = () => {
        if (formPlantRef && formPlantRef.current) {
            formPlantRef.current.submitForm();
            if(isValidInput && props.plantSearchUpdated === null && activeStep < steps.length) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = () => {
        if (formPlantRef && formPlantRef.current){
            formPlantRef.current.submitForm();
            if(isValidInput) {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
        }
    };

    const handleSetActiveStep = (currentStep: number) => {
        setActiveStep(currentStep);
    }

    const handleCloseDialog = () => {
        props.handleClose();
        setTimeout(() => {
            setActiveStep(0);
        }, 200);
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason === "backdropClick")
            return;
        handleCloseDialog()
    }

    return (
        <Dialog fullScreen={isMobile} open={props.open} onClose={handleClose} style={{borderRadius: 22}} TransitionComponent={TransitionSlideUp}>
            <DialogTitle style={{padding:0}}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                    <IconButton
                        color="secondary"
                        style={{ background: 'rgba(53, 186, 7, 0.12)' }}
                        aria-label="close dialog"
                        edge="start"
                        onClick={handleCloseDialog}
                        >
                        <CloseIcon style={{ color: (theme.palette.mode === 'dark') ? theme.palette.common.white : theme.palette.common.black}} />
                    </IconButton>
                </Toolbar>
            </DialogTitle>
            <Divider style={{margin:0}}></Divider>
            <DialogContent >
                <PlantForm
                    formRef={formPlantRef}
                    contentTextStep1={props.contentTextStep1}
                    contentTextStep2={props.contentTextStep2}
                    setIsValidInput={setIsValidInput}
                    plantSearchUpdated={props.plantSearchUpdated}
                    handleUpdateRowPlant={props.handleUpdateRowPlant}
                    handleSetActiveStep={handleSetActiveStep}
                    handleCloseDialog={handleCloseDialog}
                    steps={steps}
                    activeStep={activeStep}
                />
            </DialogContent>
            <DialogActions style={{paddingRight: '24px', paddingBottom: '24px'}}>
                {(activeStep !== 0 && props.plantSearchUpdated === null) &&
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={handleBack}
                        >
                        Retour
                    </Button>
                }
                <Button id="actionPlantButtonForm" variant="outlined" onClick={handleAction}>
                    {
                        (props.plantSearchUpdated === null) ?
                            (activeStep === steps.length - 1) ? 'Créer' : 'Suivant'
                            :
                            'Modifier'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}