import {useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, AccordionDetails, AccordionSummary } from '@mui/material';
import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import Fade from '@mui/material/Fade';

type AppCollapseProps = {
    children: React.ReactNode;
    title: string;
}

export default function AppCollapse(props: AppCollapseProps) {

    const [expanded, setExpanded] = useState(false);

    const handleExpansion = () => {
      setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade as AccordionSlots['transition'] }}
            slotProps={{ transition: { timeout: 200 } }}
            sx={{
            '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
            '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className='scada-card-title' >{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{marginTop: '-15px', marginBottom: (expanded) ?  '10px' : '0px'}}>
                {props.children}
            </AccordionDetails>
        </Accordion>
    )
}