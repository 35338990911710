import { REACT_APP_MERCURE_URL } from "../../config";

const Mercure = {
    eventSource: (plantId: number) => {
        const url = new URL(REACT_APP_MERCURE_URL + '/.well-known/mercure');
        url.searchParams.append('topic', '/control/plant/' + plantId);
        return new EventSource(url);
    },
}

export default Mercure;
