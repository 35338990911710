
export interface Checkpoint {
    id: number;
    name: string;
    description: string;
    equipmentType: CheckpointEquipmentTypeEnum;
    result: CheckpointResult | null;
}

export interface CheckpointResult {
    id: number,
    state: CheckpointResultStateEnum | null,
    result: string | null,
    date: string | null,
    data: SoutirageData[] | null
}

export interface SoutirageData {
    date: string;
    soutirage_enedis: number;
    soutirage_okwind: number;
    production: number;
}

export enum CheckpointResultStateEnum {
    IN_PROGRESS = 'IN_PROGRESS',
    OK = 'OK',
    KO = 'KO',
    UNPERFORMED = 'UNPERFORMED',
    UNCERTAIN = 'UNCERTAIN',
    FAILED = 'FAILED',
}

export enum CheckpointEquipmentTypeEnum {
    GENERAL = 'GENERAL',
    TRACKER = 'TRACKER',
    METER = 'METER',
    IMMERSION_HEATER = 'IMMERSION_HEATER',
    ESS = 'ESS',
}