import CardRow from "../CardRow";

export default function ImmersionHeaterEquipement() {

    return (
        <div>
            <h4>Stockage thermique</h4>

            <CardRow label="Type de stockage">
                <i>Bientôt disponible</i>
            </CardRow>

            <CardRow label="Type de chauffage client" >
                <i>Bientôt disponible</i>
            </CardRow>

            <CardRow label="Volume du stockage" >
                <i>Bientôt disponible</i>
            </CardRow>

        </div>
    );
}