import { Line } from 'react-chartjs-2';
import { mergeObjectsRecursive } from '../../../../utils/utils';
import { Plant } from '../../../../interfaces/Plant';
import { useEffect, useState, useRef } from 'react';
import { generateDates, formatXLabels } from '../DateService';
import dayjs, { Dayjs } from 'dayjs';
import apiV2 from '../../../../service/api/ApiV2';
import { fillMissingDate } from '../ParseRawData';
import { useTheme } from '@mui/material';
import type { ChartData, ChartOptions, ChartDataset } from 'chart.js';
import { Weather } from '../../../../interfaces/Weather';
import { forkJoin } from 'rxjs';
import { CrosshairPlugin } from '../../../../component/chartjs-plugin-crosshair/chartjs-plugin-crosshair';
import { ResizableBox } from 'react-resizable'
import "react-resizable/css/styles.css";

import GraphResizeService  from '../../../../service/GraphResizeService'

type SnowChartProps = {
    plant: Plant;
    startDate: Dayjs;
    endDate: Dayjs;
    options: ChartOptions<'line'>;
}

const defaultOptions: ChartOptions<'line'> = {
    plugins: {
        title: {
            display: true,
            text: 'Neige',
        },
        tooltip: {
            callbacks: {
                label: function(tooltipItem) {
                    return tooltipItem.dataset.data[tooltipItem.datasetIndex]? 'Oui':'Non';
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: (val: string | number) => {
                    return val === 1 ? 'Oui' :(val === 0 ? 'Non': '')
                }
            },
            min: 0,
            max: 1,
        },
    }
};

export function SnowChart(props: SnowChartProps) {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement|null>(null)
    const options = mergeObjectsRecursive(defaultOptions, props.options)
    const [chartData, setChartData] = useState<ChartData<'line'>>({labels:[], datasets: []});
    const [width, setWidth] = useState(0)

    useEffect(() => {
        GraphResizeService.checkResizeWidth(ref, width, setWidth)
      },
    [ref, width, setWidth])

    useEffect(() => {
        const expectedDates = generateDates(props.startDate, props.endDate);

        async function updateDatasets(datasets: ChartDataset<'line'>[]): Promise<void> {
            const now = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss+00:00');
            const weather = await apiV2.weather(props.startDate, props.endDate, props.plant.id);
            fillMissingDate(weather, expectedDates);
            datasets.push(
            {
                label: "Neige",
                data: weather.map((x: Weather) =>  x.date <= now ? (x.snowFall ? 1: 0) : null),
                borderColor: theme.palette.snowCurve,
                backgroundColor: theme.palette.snowCurve,
                borderWidth: 2,
            });
        }

        const datasets: ChartDataset<'line'>[] = [];
        const waitForDatasets = forkJoin({
            datasets: updateDatasets(datasets),
            });
        waitForDatasets.subscribe({
            next: () =>
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: datasets
                }),
            error: () => {
                setChartData({
                    labels: formatXLabels(expectedDates, props.plant),
                    datasets: []
                })
            }
        })
    }, [props, theme]);

    const chartHeight = 100

    return (
        <div ref={ref} className='scada-card-zoom' >
            <ResizableBox  transformScale={0.75} className="box" width={width} height={chartHeight} resizeHandles={['s']} minConstraints={[100, chartHeight]} maxConstraints={[width, chartHeight*4]}>
            {/* crosshair plugin is registered locally otherwise it can cause bug on non Line Chart*/}
                <Line options={options} data={chartData} plugins={[CrosshairPlugin]} style={{marginLeft: '15px'}}/>
            </ResizableBox>
        </div>
    )
}
