import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {StatusCodes} from 'http-status-codes';

import {Tracker} from '../../../interfaces/Tracker'
import {SerialNumber} from '../../../interfaces/tracker/SerialNumber';

import ToastService from '../../../service/ToastService';

import apiV2 from '../../../service/api/ApiV2';
import {NumberPvPanel} from "../../../interfaces/tracker/NumberPvPanel";
import {SetPvPanel} from "../../../interfaces/tracker/SetPvPanel";

interface TrackersState {
    data: Tracker[];
    loading: boolean;
    error: string | null;
}

const initialState: TrackersState = {
    data: [],
    loading: false,
    error: null,
};

export const updateTrackerSerialNumber = createAsyncThunk(
    'tracker/updateTrackerSerialNumber', async (serialNumber: SerialNumber) => {
        const response = (await apiV2.updateTrackerSerialNumber(serialNumber))
        return {response, serialNumber: serialNumber}
    });

export const updateTrackerNumberPvPanel = createAsyncThunk(
    'tracker/updateTrackerNumberPvPanel', async (numberPvPanel: NumberPvPanel) => {
        const response = (await apiV2.updateTrackerNumberPvPanel(numberPvPanel))
        return {response, numberPvPanel}
    }
);

export const updateTrackerPvPanel = createAsyncThunk(
    'tracker/updateTrackerPvPanel', async ({setPvPanel, deviceDescription}: {setPvPanel: SetPvPanel, deviceDescription: string}) => {
        const response = (await apiV2.updateTrackerPvPanel(setPvPanel))
        return {response, setPvPanel, deviceDescription}
    }
);

const getTrackerFromState = (state: TrackersState, trackerId: number): Tracker => {
    const index = state.data.findIndex((x: Tracker) => x.id === trackerId)
    return state.data[index]
}

const trackersSlice = createSlice({
    name: 'Trackers',
    initialState,
    reducers: {
        setTrackers: (state, action) => {
            state.data = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTrackerSerialNumber.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTrackerSerialNumber.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Probleme lors de la mise à jours du numéro de série';
                ToastService.error(state.error)
            })
            .addCase(updateTrackerSerialNumber.fulfilled, (state, action) => {
                state.loading = false;
                const {response, serialNumber} = action.payload;

                if (response.status !== StatusCodes.ACCEPTED) {
                    return
                }
                const tracker = getTrackerFromState(state, serialNumber.trackerId)
                tracker.serialNumber = serialNumber.serialNumber
                ToastService.success("Le numéro de série a été modifié avec succès")
            })
            .addCase(updateTrackerNumberPvPanel.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTrackerNumberPvPanel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Probleme lors de la mise à jours du nombre de panneaux';
                ToastService.error(state.error)
            })
            .addCase(updateTrackerNumberPvPanel.fulfilled, (state, action) => {
                state.loading = false;
                const {response, numberPvPanel} = action.payload;

                if (response.status !== StatusCodes.ACCEPTED) {
                    return
                }
                const tracker = getTrackerFromState(state, numberPvPanel.trackerId)
                tracker.moduleQuantity = numberPvPanel.numberPvPanel
                ToastService.success("Le nombre de panneaux a été modifié avec succès")
            })
            .addCase(updateTrackerPvPanel.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateTrackerPvPanel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Probleme lors de la mise à jours du type de panneaux';
                ToastService.error(state.error);
            })
            .addCase(updateTrackerPvPanel.fulfilled, (state, action) => {
                state.loading = false;
                const {response, setPvPanel, deviceDescription} = action.payload;

                if (response.status !== StatusCodes.ACCEPTED) {
                    return;
                }

                const tracker = getTrackerFromState(state, setPvPanel.tracker);
                tracker.pvPanel = deviceDescription;
                ToastService.success("Le type de panneaux a été modifié avec succès");
            })
    },
});

export const {setTrackers} = trackersSlice.actions;


export default trackersSlice.reducer;