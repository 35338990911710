import React, { RefObject, useImperativeHandle, ForwardRefRenderFunction, useEffect} from 'react';
import { StatusCodes } from 'http-status-codes';
import { SubmitHandler, Controller, useForm} from 'react-hook-form';

import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AutocompleteAddress from '../../../component/form/AutocompleteAddress';

import { Client } from '../../../interfaces/client/Client';
import { PlatformTypeEnum } from '../../../interfaces/client/PlatformTypeEnum';

import { ClientSearch } from '../../../interfaces/client/ClientSearch';

import apiV2 from '../../../service/api/ApiV2';
import ToastService from '../../../service/ToastService';
import { Typography } from '@mui/material';

type ClientFormProp = {
    formRef: RefObject<ClientFormHandle>;
    setIsValidInput: (value: boolean) => void;
    handleUpdateRowClient: (clientSearch: ClientSearch) => void;
    clientSearchUpdated: ClientSearch | null;
};

type ClientFormHandle = {
    submitForm: () => void;
};

const ClientForm: ForwardRefRenderFunction<ClientFormHandle, ClientFormProp> = (props) => {
    const { formRef, setIsValidInput } = props;
    const {
      control,
      register,
      handleSubmit,
      trigger,
      setValue,
      reset,
      watch,
      setError,
      formState: {errors, isValid}
    } = useForm<Client>(
      {
        defaultValues: {
          id: null,
          firstName: "",
          lastName: "",
          aliasCompany: "",
          address: "",
          email: "",
          phone: "",
          platformType: PlatformTypeEnum.OKWIND,
          notifiedByEmail: false,
          notifiedBySms: false
        }
      });

    const serverSideValidForm = (response: Response, errorMessage: string) => {
        if(response.status === StatusCodes.BAD_REQUEST){
            response.json().then(resp=>{
                const errorsServerSide = JSON.parse(resp.detail)
                if (errorsServerSide.input === 'email'){
                    setError('email', {
                        type: "server",
                        message: errorsServerSide.message,
                    });
                }
            })
        }else{
            ToastService.error(errorMessage);
        }
    }

    const onSubmit: SubmitHandler<Client> = data => {
        if(props.clientSearchUpdated){
            apiV2.updateClient(data).then((response) => {
                if(response.ok){
                    setIsValidInput(isValid)
                    const newClientSearchUpdated = {} as ClientSearch;
                    newClientSearchUpdated.id = (props.clientSearchUpdated) ? props.clientSearchUpdated.id : -1;
                    newClientSearchUpdated.firstName = data.firstName;
                    newClientSearchUpdated.lastName = data.lastName;
                    newClientSearchUpdated.address = data.address;
                    newClientSearchUpdated.aliasCompany = data.aliasCompany;
                    newClientSearchUpdated.email = data.email;
                    newClientSearchUpdated.phone = data.phone;
                    newClientSearchUpdated.plantIds = (props.clientSearchUpdated) ? props.clientSearchUpdated.plantIds : [];
                    props.handleUpdateRowClient(newClientSearchUpdated)
                    ToastService.success('Le client ' + data.firstName + ' ' + data.lastName + ' vient d\'être modifié');
                }else{
                    serverSideValidForm(response, 'Erreur durant la modification de l\'utilisateur')
                }
            });
        }else{
            apiV2.createClient(data).then((response) => {
                if(response.ok){
                    setIsValidInput(isValid)
                    ToastService.success('Un nouveau client vient d\'être créé');
                }else{
                    serverSideValidForm(response, 'Erreur durant la création du client')
                }
            });
        }
    };

    useEffect(() => {
        if (props.clientSearchUpdated) {
            apiV2.detailClient(props.clientSearchUpdated.id).then((client :Client) => {
                reset(client)
                trigger()
            });
         }
    }, [])

    // permet d appeler le submit form depuis le composant parent
    useImperativeHandle(formRef, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        },
    }));

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} direction="row" sx={{marginBottom: 0}}>
                <Controller
                    name="firstName"
                    control={control}
                    render={({field})=>(
                        <TextField
                        {...field}
                        id="ClientFirstNameInput"
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Prénom"
                        required
                        error={!!(errors.firstName) }
                        {...register("firstName", {
                                required: "Le prénom est obligatoire",
                                minLength: {
                                    value: 2,
                                    message: "Le prénom doit contenir au moins 2 caractères"
                                }
                            })
                        }
                        fullWidth
                        helperText={(errors.firstName) ? errors.firstName.message : null}
                    />
                )} />
                <Controller
                    name="lastName"
                    control={control}
                    render={({field})=>(
                        <TextField
                        {...field}
                        id="ClientLastNameInput"
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Nom"
                        required
                        error={!!(errors.lastName) }
                        {...register("lastName", {
                                required: "Le nom est obligatoire",
                                minLength: {
                                    value: 2,
                                    message: "Le nom doit contenir au moins 2 caractères"
                                }
                            })
                        }
                        fullWidth
                        helperText={(errors.lastName) ? errors.lastName.message : null}
                    />
                )} />
                </Stack>
                <Controller
                    name="email"
                    control={control}
                    render={({field})=>(
                        <TextField
                        {...field}
                        id="ClientEmailInput"
                        type="email"
                        variant='outlined'
                        color='primary'
                        label="Email"
                        required
                        error={!!(errors.email) }
                        {...register("email", {
                                required: "L'email est obligatoire",
                                pattern: {
                                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                    message: "L'email n'est pas valide"
                                }
                            })
                        }
                        fullWidth
                        helperText={(errors.email) ? errors.email.message : null}
                        sx={{mb: 4}}
                    />
                )} />
                <FormControl style={{marginBottom: '0px'}} fullWidth>
                    <Controller
                        name="address"
                        control={control}
                        render={({field})=>(
                            <AutocompleteAddress
                                {...field}
                                ref={null}
                                label="Adresse"
                                required={true}
                                preloadvalue={watch('address')}
                                register={register}
                                options={{ required: true }}
                                setValue={setValue}
                                trigger={trigger}
                                address={`address`}
                                placeId={null}
                                latitude={null}
                                longitude={null}
                                error={!!errors.address}
                                />
                    )} />
                </FormControl>
                <Controller
                    name="platformType"
                    control={control}
                    render={({field})=>(
                        <RadioGroup
                            {...field}
                            style={{marginTop: '-30px', marginBottom: '0px'}}
                            row
                            defaultValue={PlatformTypeEnum.OKWIND}
                            aria-labelledby="ClientPlatformRadioGroup"
                        >
                            <FormControlLabel data-testid="clientOkwindRadio" value={PlatformTypeEnum.OKWIND} control={<Radio />} label="Client Okwind" />
                            <FormControlLabel data-testid="clientLumiooRadio" value={PlatformTypeEnum.LUMIOO} control={<Radio />} label="Client Lumioo" />
                        </RadioGroup>
                )} />


                <Typography
                    style={{marginTop: '10px', marginBottom: '20px'}}
                    color="text.secondary"
                >
                    Informations complémentaires
                </Typography>
                <Controller
                    name="aliasCompany"
                    control={control}
                    render={({field})=>(
                        <TextField
                        {...field}
                        id="ClientAliasCompanyInput"
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Société (facultatif)"
                        inputProps={{
                            maxLength: 50,
                        }}
                        error={!!(errors.aliasCompany) }
                        {...register("aliasCompany", {
                                minLength: {
                                    value: 2,
                                    message: "Le nom de la société doit contenir au moins 2 caractères"
                                }
                            })
                        }
                        helperText={(errors.aliasCompany) ? errors.aliasCompany.message : null}
                        fullWidth
                        sx={{mb: 4}}
                    />
                )} />
                <Controller
                    name="phone"
                    control={control}
                    render={({field})=>(
                        <TextField
                            {...field}
                            id="ClientPhoneInput"
                            type="tel"
                            variant='outlined'
                            color='primary'
                            label="Numéro de téléphone"
                            error={!!(errors.phone) }
                            inputProps={{ maxLength: 14 }}
                            {...register("phone", {
                                    onChange: (e) => {
                                        const phone = e.target.value;
                                        // On autorise uniquement des chiffres, et on souhaite représenter
                                        // le numéro de téléphone avec des espaces tous les 2 caractères
                                        // pour en faciliter la lecture
                                        setValue('phone', phone.replace(/[^0-9]/gi, '').replace(/(.{2})/g, '$1 ').trimEnd())
                                        if (!(errors.phone)) {
                                            setValue('notifiedBySms', false)
                                        }
                                        trigger('phone');
                                    },
                                    pattern: {
                                        value: /^[0-9\s]*$/i,
                                        message: 'Le numéro ne doit contenir que des chiffres',
                                    },
                                    minLength: {
                                        value: 14,
                                        message: "Le numéro de téléphone doit contenir 10 chiffres"
                                    },
                                    maxLength: {
                                        value: 14, // format base xx xx xx
                                        message: "Le numéro de téléphone doit contenir 10 chiffres"
                                    }
                                })
                            }
                            helperText={(errors.phone) ? errors.phone.message : null}
                            fullWidth
                            sx={{mb: 4}}
                        />
                )} />

                <FormGroup>
                    <Controller
                        name="notifiedByEmail"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel data-testid="emailNotificationCheckbox" control={<Switch checked={value} onChange={onChange} />} label="Notification par email" />

                        )}
                    />
                    <Controller
                        name="notifiedBySms"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControlLabel
                                data-testid="smsNotificationCheckbox"
                                control={
                                <Switch
                                    checked={value}
                                    onChange={onChange}
                                    disabled={(watch('phone') === '' || !!(errors.phone))} />}
                                    label="Notification par sms"
                                />
                        )}
                    />
                </FormGroup>

            </form>
        </React.Fragment>
    )
};

export default React.forwardRef(ClientForm);
