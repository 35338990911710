import {Divider} from "@mui/material";

import CardRow from "../CardRow";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../../store/store";
import React, {useEffect, useRef} from "react";
import NumberPvPanelForm from "./form/NumberPvPanelForm";
import PvPanelTypeForm from "./form/PvPanelTypeForm";
import {getPvPanelTypes} from "../../../../../store/form/pvPanelTypes.store";

interface propsInterface {
    index: number;
}

export function TrackerEquipment(props: propsInterface) {
    const trackers = useSelector((state: RootState) => state.trackers);

    type NumberPvPanelFormRef = React.ElementRef<typeof NumberPvPanelForm>;
    type PvPanelTypeFormRef = React.ElementRef<typeof PvPanelTypeForm>;

    type CardRowHandle = React.ElementRef<typeof CardRow>;

    const numberPvPanelForm = useRef<NumberPvPanelFormRef>(null);
    const pvPanelTypeForm = useRef<PvPanelTypeFormRef>(null);

    const firstCardRef = useRef<CardRowHandle>(null);
    const secondCardRef = useRef<CardRowHandle>(null);

    const dispatch = useDispatch<AppDispatch>();
    const pvPanelTypesList = useSelector((state: RootState) => state.pvPanelTypes.data);

    const handleNumberPvPanelSubmit = () => {
        if (numberPvPanelForm && numberPvPanelForm.current) {
            numberPvPanelForm.current.submitForm();
        }
    };

    const handlePvPanelTypeSubmit = () => {
        if (pvPanelTypeForm && pvPanelTypeForm.current) {
            pvPanelTypeForm.current.submitForm();
        }
    }

    const handleSuccess = () =>  {
        if (firstCardRef && firstCardRef.current) {
            firstCardRef.current.setIsEditing(false);
        }
        if (secondCardRef && secondCardRef.current) {
            secondCardRef.current.setIsEditing(false);
        }
    }

    useEffect(() => {
        if(pvPanelTypesList.length === 0) dispatch(getPvPanelTypes())
    }, []);

    return (
        <div>
            <h4>Tracker</h4>
            <CardRow label="Type de tracking">
                {trackers.data[props.index].trackingType}
            </CardRow>

            <CardRow
                label="Panneaux solaires"
                isEditable={true}
                chidrenEditValidate={handlePvPanelTypeSubmit}
                cardRef={secondCardRef}
                childrenEdit={
                    <PvPanelTypeForm formRef={pvPanelTypeForm} index={props.index} onSuccess={handleSuccess}/>
                }
            >
                {trackers.data[props.index].pvPanel}
            </CardRow>

            <CardRow
                label="Nombre de panneaux"
                isEditable={true}
                chidrenEditValidate={handleNumberPvPanelSubmit}
                cardRef={firstCardRef}
                childrenEdit={
                    <NumberPvPanelForm formRef={numberPvPanelForm} index={props.index} onSuccess={handleSuccess}/>
                }
            >
                {trackers.data[props.index].moduleQuantity}
            </CardRow>

            <CardRow label="Anémomètre">
                {trackers.data[props.index].anemometer}
            </CardRow>
            {
                (trackers.data[props.index].linearActuatorType) &&
                <CardRow label="Vérin">
                    {trackers.data[props.index].linearActuatorType?.label}
                </CardRow>
            }

            <div style={{marginTop: '20px'}}></div>
            <Divider></Divider>

            <h4>Onduleur</h4>

            <CardRow label="Référence" >
                {trackers.data[props.index].inverter}
            </CardRow>
        </div>
    )
}

