import { Alert, AlertTitle } from "@mui/material";
import { Alarm } from "../../../../interfaces/Alarm"
import dayjs from "dayjs";

type AlarmDetailProps = {
    alarm: Alarm
}

function AlarmSeverity(level: number) {
    if (level >= 30) return 'error';
    if (level >= 20) return 'warning';
    if (level >= 10) return 'success';
    return 'info';
}

export function AlarmDetail(props: AlarmDetailProps) {
  const dateFormated = dayjs(props.alarm.begin);

  return (
        <Alert sx={{ marginTop: "10px", fontSize: "12px" }} severity={AlarmSeverity(props.alarm.type.level)}>
            <AlertTitle sx={{ fontWeight: 'bold', fontSize: "12px" }}>{props.alarm.type.label}</AlertTitle>
            <AlertTitle sx={{ fontWeight: 'bold', fontSize: "12px" }}>{props.alarm.type.reference}</AlertTitle>
            {props.alarm.type.savDescription}<br/>
            <i>Depuis le {dateFormated.format('DD/MM/YYYY HH:mm')}</i>
        </Alert>
    )
}