import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import SynchronisedDiv from '../SynchronisedDiv';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import apiV2 from '../../../../../service/api/ApiV2';
import { forkJoin } from 'rxjs';
import { MeterStatus } from '../../../../../interfaces/MeterStatus';
import { CardMedia, Grid, IconButton, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import image from '../../../../../assets/icon_meter.svg'
import { Meter } from '../../../../../interfaces/Meter';
import MeterDialog from './MeterDialog';

type MeterCardProps = {
  meter: Meter;
}

export default function MeterCard(props: MeterCardProps) {
  const theme = useTheme();
  const [meterStatus, setMeterStatus] = useState<MeterStatus>();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
  setOpen(false);
  };

  useEffect(() => {
    const status = forkJoin({
      meterStatus: apiV2.meterStatus(props.meter.id),
    });

    status.subscribe({
      next: (results) => {
        setMeterStatus(results.meterStatus);
      },
    })
  }, [props.meter.id]);
  if (!meterStatus) return <></>;
  return (
    <Card className='scada-card'>
      <Grid container style={{paddingTop:'15px', paddingLeft:'10px', paddingBottom: '20px'}}>
        <Grid item xs={2}>
          <CardMedia
            style={{backgroundColor: (theme.palette.mode === 'dark') ? '#757575' : 'transparent', borderRadius: '50%', padding: '2px', marginLeft: '15px'}}
            component="img"
            image={image}
            className='scada-card-image'
            />
        </Grid>
        <Grid item xs={8} style={{ paddingLeft: '10px'}}>
          <Typography component="div" className='scada-card-title'>
            Compteur principal
          </Typography>
          <SynchronisedDiv isSynchronised={meterStatus.isSynchronised} />
        </Grid>
        <Grid item xs={2} style={{marginTop: '5px', paddingRight: '10px', textAlign: 'right'}}>
          <Tooltip title="Paramètres">
            <IconButton
                aria-label="delete" onClick={handleOpen}
            >
                <SettingsIcon fontSize="inherit" color="secondary"/>
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <MeterDialog
            meter={props.meter}
            open={open}
            handleClose={handleClose}
        >
        </MeterDialog>
    </Card>
  );
}