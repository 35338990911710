import keycloak from "../../Keycloak";
import {
    createDirectus,
    readAssetRaw,
    readField,
    readFiles,
    readFolder,
    readFolders,
    readItems,
    rest,
    RestCommand,
    withToken
} from '@directus/sdk';
import {Feature} from "../../interfaces/directus/Feature";
import {FileSearch} from "../../interfaces/documentation/FileSearch";
import {Folder} from "../../interfaces/documentation/Folder";
import {Release, StatusEnum} from "../../interfaces/directus/Release";
import {REACT_APP_DIRECTUS_URL} from "../../config";
import {Field} from "../../interfaces/documentation/Field";

interface Schema {
    features: Feature[]; // regular collections are array types
    partners_releases: Release[];
}

const client = createDirectus<Schema>(REACT_APP_DIRECTUS_URL).with(rest());
const apiDirectus = {

    request: function <Type>(command: RestCommand<unknown, Schema>): Promise<Type> {
        return client.request<Type>(
            withToken<Schema, Type>(keycloak.token ?? '', command)
        );
    },

    getFeatures: async function (): Promise<Array<Release>> {
        return await this.request(
            readItems('partners_releases',
                {
                    filter: {
                        status: { _eq: StatusEnum.PUBLISHED },
                    },
                    fields: ['id', 'published', { features: ['id', 'title', 'description', 'url'] }],
                    sort: ['-published'],
                }
            ));
    },

    getFiles: async function (searchValue: string|null, documentTypes: string[]|null, models: string[]|null, languages: string[]|null): Promise<Array<FileSearch>> {
        const options: any = {
            filter: {
                type: {
                    _in: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv', 'image/jpeg', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text', 'application/vnd.ms-powerpoint',  'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/x-rar-compressed'],
                },
                _or: [
                ],
                _and: [
                ],
            },
          sort: ['-Publication'],
        }

        if (searchValue) {
            options.filter._or.push(
              {
                  title: {
                      _icontains: searchValue
                  }
              },
              {
                  description: {
                      _icontains: searchValue
                  }
              }
            );
        }

        if (languages) {
            options.filter._and.push({
                _or: languages.map(language => ({
                    Langage: {
                        _eq: language
                    }
                }))
            });
        }

        if (documentTypes) {
            options.filter._and.push({
                Type_de_document: {
                    _in: documentTypes
                }
            });
        }

        if (models) {
            options.filter._and.push({
                _or: models.map(model => ({
                    Modele: {
                        _contains: model
                    }
                }))
            });
        }

        return await this.request(readFiles(options))
    },

    getFilesFromFolder : async function (folderId: string): Promise<Array<FileSearch>> {
        return await this.request(readFiles(
            {
                filter: {
                    folder: {
                        _eq: folderId,
                    }
                },
            }
        ))
    },

    getImage : async function (fileId: string): Promise<ReadableStream<Uint8Array>> {
        return await this.request(readAssetRaw(fileId, {
            width: 90,
            height: 90,
            quality: 100
        }))
    },

    getFolder: async function (id: string): Promise<Folder> {
        return await this.request(
            readFolder(id, {
                fields: ['*'],
            })
        )
    },

    getAllFolders: async function (): Promise<Folder[]> {
        return await this.request(
            readFolders({
                fields: ['*'],
            })
        )
    },

    getCustomField: async function (fieldName: string): Promise<Field> {
        return await this.request(
            readField('directus_files', fieldName)
        )
    },
};

export default apiDirectus;