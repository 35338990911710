import Grid from '@mui/material/Grid';
import ListFileSearchView from './ListFileSearchView'

export default function DocumentationView() {
  return (
    <div style={{ justifyContent: "center", display: "flex", width:"100%" }}>
      <Grid container>
          <ListFileSearchView></ListFileSearchView>
      </Grid>
    </div>
  )
}