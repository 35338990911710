import { Button } from "@mui/material"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { GetAlarmNotViewedBySav } from "../../../../interfaces/GetAlarmNotViewedBySav"

type AlarmNotViewedBySavItemProps = {
    alarm: GetAlarmNotViewedBySav,
    onClick: (a: string) => void
}

export default function AlarmNotViewedBySavItem(props: AlarmNotViewedBySavItemProps) {

    const getSeverityFromAlarmLevel = (alarmLevel: number) => {
        switch (alarmLevel) {
            case 0:
                return "info"
            case 10:
                return "success"
            case 20:
                return "warning"
            case 30:
                return "error"
            default:
                return "info"
        }
    }

    return (
        <Alert
            style={{width: '100%'}}
            icon={false}
            severity={getSeverityFromAlarmLevel(props.alarm.alarmLevel)}
            action={
                <Button
                    style={{marginRight: '10px', marginTop: '5px'}}
                    color='secondary'
                    variant="outlined"
                    onClick={() => props.onClick(props.alarm.id)}>
                    Traité
                </Button>
              }
        >
            <AlertTitle style={{ fontSize: 12, marginBottom: -20 }}>{props.alarm.alarmReference.replaceAll('_', ' ')}</AlertTitle>
            <span style={{ fontSize: 12, marginLeft: 10 }}>
                <br />
                Début : {props.alarm.begin}
            </span>
        </Alert>
    )
}
