import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export interface TrackerAlarmSelectedState {
  value: string | null;
}

const initialState: TrackerAlarmSelectedState = {
  value : null,
}

export const trackerAlarmSelectedSlice = createSlice({
  name: 'TrackerAlarmSelected',
  initialState,
  reducers: {
    setTrackerAlarmSelected: (state, action) => {
      state.value = action.payload
    },
  }
})

export const { setTrackerAlarmSelected } = trackerAlarmSelectedSlice.actions

export const selectTrackerAlarmSelected = (state: RootState) => state.trackerAlarmSelected.value

export default trackerAlarmSelectedSlice.reducer
