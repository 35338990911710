import { Autocomplete, TextField } from "@mui/material";

type optionItem = {id: string | number, label: string}

type onChangeInput = (number | string) | (number | string)[] | null

type FilterMultipleProps = {
    options: optionItem[],
    label: string,
    onChange: (value: onChangeInput) => void,
    value: (string | number)[] // value is the list of ids of item that are selected
    style?: React.CSSProperties
}
export default function FilterMultiple(props: FilterMultipleProps) {

    function updateSelectedValues(value: optionItem[] | null): void {
        if (value === null) {
            props.onChange(value)
            return
        }
        props.onChange(value.map(x => x.id))
    }
    // convert the props.defaultValue to "options" format
    function valueToOptions(defaultValue: (string | number)[], options: optionItem[]): optionItem[] {
        return defaultValue.map(value => options.filter(option => value === option.id)[0])
    }

    return (
        <Autocomplete
            disablePortal
            sx={props.style}
            options={props.options}
            multiple={true}
            value={valueToOptions(props.value, props.options)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} label={props.label}  size="small" onClick={e => {e.stopPropagation()}}/>}
            onChange={(_, value) => {updateSelectedValues(value)}}
            onClose={e => {e.stopPropagation()}}
        />
    );
}