import { useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent, SxProps, Theme } from "@mui/material";
import { FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { Type } from '../../../../interfaces/ess/Type';

interface Props<T extends FieldValues> extends UseControllerProps<T> {
  error: boolean | undefined
  sx?: SxProps<Theme>
  types: Type[]
}

export function SelectType<T extends FieldValues>(props: Props<T>) {
  const { field } = useController({ name: props.name, control: props.control })

  const [selectedValue, setSelectedValue] = useState(props.defaultValue as string)

  useEffect(() => {
    if (props.types.length === 0) {
      return
    }

    const firstId = props.types[0].id
    setSelectedValue(firstId)
    field.onChange(firstId)
  }, [props.types])

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value)
    field.onChange(event.target.value)
  }

  return <Select
    name={props.name}
    value={selectedValue}
    onChange={handleChange}
    label={"Ref Type"} // masqué, mais prépare une place quand le label est shrinked, et évite que la ligne passe à travers du label
    sx={props.sx}
    error={props.error}
  >
    {props.types.map((type: Type, idx) =>
      <MenuItem key={idx} value={type.id}>{type.label}</MenuItem>)
    }
  </Select>
}
