import { Subject } from 'rxjs';
import { AlertColor } from '@mui/material';

interface ConfirmDialogMessage {
  size: string;
  title: string;
  askMessage: string;
  confirmMessage: string;
  severity: AlertColor;
  callbackConfirmation: () => void;
}

const subject = new Subject<ConfirmDialogMessage>();
const closeSubject = new Subject<boolean>();

const ConfirmDialogService = {
    info: function(size: string, title: string, askMessage: string, confirmMessage: string,  callback: () => void): void {
      subject.next({
        size: size,
        title: title,
        askMessage: askMessage,
        confirmMessage: confirmMessage,
        severity: 'info',
        callbackConfirmation: callback
      });
    },
    warning: function(size: string, title: string, askMessage: string, confirmMessage: string, callback: () => void): void {
      subject.next({
        size: size,
        title: title,
        askMessage: askMessage,
        confirmMessage: confirmMessage,
        severity: 'warning',
        callbackConfirmation: callback
      });
    },
    error: function(size: string, title: string, askMessage: string, confirmMessage: string, callback: () => void): void {
      subject.next({
        size: size,
        title: title,
        askMessage: askMessage,
        confirmMessage: confirmMessage,
        severity: 'error',
        callbackConfirmation: callback
      });
    },
    close: function(): void {
      closeSubject.next(false);
    },
    subject: function(): Subject<ConfirmDialogMessage> {
      return subject;
    },
    getCloseSubject: function(): Subject<boolean> {
      return closeSubject;
    }
}

export default ConfirmDialogService;
